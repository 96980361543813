import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, Legend, Colors, Tooltip, BarElement, BarController, CategoryScale, LineController, LinearScale, PieController, ArcElement, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-dashboard-customer-vendor',
    templateUrl: './dashboard-customer-vendor.component.html',
    styleUrls: ['./dashboard-customer-vendor.component.scss']
})
export class DashboardCustomerVendorComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpService,
        public loading: LoadingService
    ) {
        Chart.register(
            ChartDataLabels,
            Colors,
            Legend,
            Tooltip,
            BarElement,
            BarController,
            CategoryScale,
            LineController,
            LinearScale,
            PieController,
            ArcElement
        );
    }

    @ViewChild('barProgress') barProgress!: ElementRef;
    private apiPath = 'dashboard/customer-vendor';
    barProgressChart!: any;
    data: any = {};
    poList: any = [];
    selectedPo: string = '';
    progressLoading = true;

    async getPo(): Promise<void> {
        const r = await this.http.Get('purchase-orders', { forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedPo = '';
        }
        this.progressLoading = true;
        const r = await this.http.Get(this.apiPath, { po_id: (this.selectedPo || null), forceView: true }, progressOnly);
        this.data = r.response?.result || {};

        this.barProgressChart?.destroy();
        let barProgressData: ChartData<'bar'> = { labels: this.data?.labels, datasets: this.data?.datasets};
        this.barProgressChart = new Chart(this.barProgress.nativeElement, {
            type: 'bar',
            data: barProgressData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return value + '%';
                        }
                    },
                }
            }
        });
        this.progressLoading = false;
    }

    ngOnInit(): void {
        this.getPo();
    }

    ngAfterViewInit(): void {
        this.getData();
    }
}
