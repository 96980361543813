import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PrintService } from 'src/app/services/print.service';

@Component({
    selector: 'app-pr-supplier-detail',
    templateUrl: './pr-supplier-detail.component.html',
    styleUrls: ['./pr-supplier-detail.component.scss']
})
export class PrSupplierDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private el: ElementRef,
        public printService: PrintService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
            this.route.queryParams.subscribe(q => {
                const p = new URLSearchParams(q).toString();
                if (p) {
                    this.id += '?' + p;
                }
            });
        });
    }
    @ViewChild('approveModal') approveModal: any;
    @ViewChild('rejectModal') rejectModal: any;
    @ViewChild('receiveModal') receiveModal: any;
    private apiPath = 'pr-suppliers';
    id: any;
    data: any = {}
    supplierList = new Subject<any[]>();

    approveDialog: any = {
        modal: null,
        show: () => {
            this.approveDialog.modal = this.modalService.open(this.approveModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            fdata.append('id', this.id);
            this.http.Post(this.apiPath + '/approve', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data approved successfully", 'Success');
                    this.ngOnInit();
                    this.approveDialog.modal.close();
                }
            })
        }
    }

    rejectDialog: any = {
        modal: null,
        remarks: null,
        show: () => {
            this.rejectDialog.modal = this.modalService.open(this.rejectModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            fdata.append('id', this.id);
            fdata.append('remarks', this.rejectDialog.remarks);
            this.http.Post(this.apiPath + '/reject', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data rejected successfully", 'Success');
                    this.ngOnInit();
                    this.rejectDialog.modal.close();
                }
            })
        }
    }

    allocateDialog: any = {
        modal: null,
        show: () => {
            const emptied = this.data.details.filter((e: any) => !e.vendor_id);
            if (emptied.length == this.data.details.length) {
                this.toastr.error("Please Select Supplier Name", 'Select Supplier');
                this.el.nativeElement.querySelectorAll('#vendor-' + emptied[0].id + ' input[type="text"]')?.[0]?.focus();
                return;
            }
            this.allocateDialog.modal = this.modalService.open(this.receiveModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            const details = this.data.details.map((e: any) => {
                return { id: e.id, vendor_id: e.vendor_id };
            });
            const data = { id: this.id, details };
            fdata.append('data', JSON.stringify(data));
            this.http.Post(this.apiPath + '/allocate', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data received successfully", 'Success');
                    this.ngOnInit();
                    this.allocateDialog.modal.close();
                }
            })
        }
    }

    rejectRemarks(): any {
        const appr = (this.data.approvals.find((e: any) => e.status == -1)) || {};
        return appr.remarks || null;
    }

    myApproval(): any {
        const e: any[] = (this.data.approvals || []).filter((e: any) => e.is_me && e.show_notification) || [];
        return e[e.length - 1] || {};
    }

    afterAllocate(): boolean {
        return (this.data.approvals || []).filter((e: any) => e.level_name == 'allocation' && e.status == 1)?.length > 0;
    }

    async getSupplier(materials: any[]): Promise<void> {
        this.supplierList.next([]);
        const r = await this.http.Get('supplier-prices', { filter: { status: 1 }, has_materials: materials.join(','), forceView: true });
        this.supplierList.next(r.response?.result?.data || []);
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, { forceView: true }).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data
            } else {
                this.toastr.error('Data not found', 'Error');
                this.back();
            }
        });

    }
}
