export const environment = {
    production: false,
    apiUrl: 'https://api-srp.karyaoptima.com/cms',
    assetUrl: 'https://api-srp.karyaoptima.com/uploads/', //Always add slash at end of the line
    appName: 'srp_app.dev',
    appKey: '8YHksYi5rHZgXOwN4qjLrCydnhvQLEAe',
    firebase: {
        apiKey: "AIzaSyAjDUs5e_hi4j1jii6UfzAMuYGG28kZbb8",
        authDomain: "srp-project-management-f5929.firebaseapp.com",
        projectId: "srp-project-management-f5929",
        storageBucket: "srp-project-management-f5929.appspot.com",
        messagingSenderId: "91737847318",
        appId: "1:91737847318:web:e4e7c7ab3c391ecca02737",
        vapidKey: 'BG9UUcSI8U91UT4PzkK3jctDDS1fzb4iwrA3d1Q3e-uu2Z8orFXlfWIRMEk6ZQARuYLhRHPQnavUewATMz3MMK0'
    }
}
