<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="div mb-3">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" [disabled]="loading.status" (click)="back()" title="Back">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Vendor Progress</h4>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="loading.status" class="col-12 text-center">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="!loading.status" class="col-12">
                        <div class="row mb-3">
                            <div class="col-12">
                                <table cellpadding="3">
                                    <tr>
                                        <td>PO Number</td>
                                        <td>:</td>
                                        <td>{{data.item?.number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer Name</td>
                                        <td>:</td>
                                        <td>{{data.item?.contract?.customer_name}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-2">
                                    <button class="btn btn-primary" (click)="download()">Download</button>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="text-center">#</th>
                                                <th>Site name</th>
                                                <th class="text-end">Project Progress</th>
                                                <th class="text-end">Document Progress</th>
                                                <th class="text-end">Total Progress</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of data?.data;let i=index;">
                                                <td class="text-center">
                                                    {{ i+1 }}
                                                </td>
                                                <td>
                                                    {{ item.name }}
                                                </td>
                                                <td class="text-end">
                                                    {{ item.vendor_progress | number }}%
                                                </td>
                                                <td class="text-end">
                                                    {{ item.vendor_document | number }}%
                                                </td>
                                                <td class="text-end">
                                                    {{ (item.vendor_document+item.vendor_progress) | number }}%
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
