import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';

@Component({
    selector: 'app-contract-edit',
    templateUrl: './contract-edit.component.html',
    styleUrls: ['./contract-edit.component.scss']
})
export class ContractEditComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        private dateParse: DatePickerParserService,
        public loading: LoadingService,
        public fileHandler: FileHandlerService,
        private auth: AuthService,
        private el: ElementRef,
        private fb: FormBuilder,
        private toastr: ToastrService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'contracts';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = this.fb.group({});
    formChanged = false;
    projectTypeList: any = [];
    projectCategoryList: any = [];

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }

        let fdata = new FormData();
        form.value.status = Number(form.value.status);
        form.value.contract_date = this.dateParse.toYMD(form.value.contract_date);
        if (form.value.start_date) {
            form.value.start_date = this.dateParse.toYMD(form.value.start_date);
        }
        if (form.value.end_date) {
            form.value.end_date = this.dateParse.toYMD(form.value.end_date);
        }
        if (form.value?.documents?.length) {
            form.value?.documents?.filter((i: any) => i.file?.file && !i.file?.file?.downloadable).forEach((i: any) => {
                const ext = i.file?.file?.name?.split('.').pop();
                fdata.append('files[]', i.file.file, i.uuid + '.' + ext);
            });
            form.value.documents = form.value?.documents?.map((i: any) => {
                return { id: (i.id || null), name: i.name, uuid: i.uuid };
            });
        }
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    documents = {
        get: () => {
            return this.formGroup.get('documents')?.value as any;
        },
        add: () => {
            const val: any = this.documents.get() || [];
            val.push({ name: null, file: {}, uuid: v4() });
            //this.formGroup.get('documents')?.setValue(val);
        },
        remove: (i: number) => {
            const val: any = this.documents.get() || [];
            val.splice(i, 1);
            // this.formGroup.get('documents')?.setValue(val);
        },
        browseFile: (i: number) => {
            document.getElementById(`file-input-${i}`)?.click();
        }
    };

    async getProjectType(): Promise<void> {
        const r = await this.http.Get('project-types', { filter: { status: 1 }, forceView: true });
        this.projectTypeList = r.response?.result?.data || [];
    }

    async getProjectCategory(): Promise<void> {
        if (!this.formGroup.get('project_type_id')?.value) {
            this.formGroup.get('project_category_id')?.reset();
            this.formGroup.get('project_category_id')?.disable();
        }
        const r = await this.http.Get('project-categories', { filter: { status: 1, project_type_id: this.formGroup.get('project_type_id')?.value }, forceView: true });
        this.projectCategoryList = r.response?.result?.data || [];
        if (this.projectCategoryList.length) {
            this.formGroup.get('project_category_id')?.enable();
        }
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.getProjectType();
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key == 'contract_date' || key == 'start_date' || key == 'end_date') {
                        const val = r?.response?.result?.data[key] ? this.dateParse.toObject(r?.response?.result?.data[key]) : null;
                        this.formGroup.addControl(key, new FormControl(val));
                    } else if (key == 'documents') {
                        const docs = (r?.response?.result?.data[key] as any[]).map((e: any) => {
                            const fileName = e.file;
                            e.file = { file: { name: fileName, downloadable: true } };
                            e.uuid = v4();
                            return e;
                        });
                        this.formGroup.addControl(key, new FormControl(docs));
                    } else {
                        this.formGroup.addControl(key, new FormControl(r?.response?.result?.data[key]));
                    }
                });
                this.getProjectCategory();
            } else {
                this.back();
            }
        });
    }

    downloadFile(file: string): void {
        const url = environment.apiUrl + '/download/' + file + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

}

