<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Add Vendor PR</h4>
                    <form #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-12">
                        <div class="row" [formGroup]="formGroup">
                            <div class="col-6">
                                <div class="form-floating mb-4">
                                    <div class="input-group">
                                        <div class="form-floating form-floating-group flex-grow-1">
                                            <input type="text" readonly (click)="tfDate.toggle()" ngbDatepicker #tfDate="ngbDatepicker" formControlName="requested_at" [class.is-invalid]="formGroup.get('requested_at')?.invalid && (theForm.submitted || formGroup.get('requested_at')?.touched)" placeholder="..." class="form-control border-end-0" [attr.disabled]="loading.status ? 'disabled' : null" required>
                                            <label>Request Date</label>
                                        </div>
                                        <div class="border border-start-0 d-flex align-items-center rounded-end" [class.border-danger]="formGroup.get('requested_at')?.invalid && (theForm.submitted || formGroup.get('requested_at')?.touched)">
                                            <button type="button" class="btn btn-sm p-0 m-0 me-2 text-muted" (click)="tfDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                                <i class="material-icons">today</i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('requested_at')?.invalid && (theForm.submitted || formGroup.get('requested_at')?.touched)">
                                        <small *ngIf="formGroup.get('requested_at')?.errors?.required">Please select date</small>
                                        <small *ngIf="formGroup.get('requested_at')?.errors?.serverError">{{ formGroup.get("requested_at")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" [items]="contractList" bindLabel="project_name" bindValue="id" [clearable]="false" (ngModelChange)="details=[];getPO()" placeholder="-" formControlName="contract_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('contract_id')?.invalid && (theForm.submitted || formGroup.get('contract_id')?.touched)" required>
                                        <ng-template class="d-flex" ng-option-tmp let-item="item">
                                            <div>{{item.project_name}}</div>
                                            <div class="small text-muted">Contract: {{item.contract_number}}</div>
                                        </ng-template>
                                    </ng-select>
                                    <label>Project Name</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('contract_id')?.invalid && (theForm.submitted || formGroup.get('contract_id')?.touched)">
                                        <small *ngIf="formGroup.get('contract_id')?.errors?.required">Please select project name</small>
                                        <small *ngIf="formGroup.get('contract_id')?.errors?.serverError">{{ formGroup.get("contract_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" [clearable]="false" placeholder="-" formControlName="purchase_order_id" (ngModelChange)="details=[];getSite()" loadingText="Loading..." [class.is-invalid]="formGroup.get('purchase_order_id')?.invalid && (theForm.submitted || formGroup.get('purchase_order_id')?.touched)" required>
                                        <ng-option *ngFor="let item of poList" [value]="item.id">{{item.number}}</ng-option>
                                    </ng-select>
                                    <label>PO Customer</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('purchase_order_id')?.invalid && (theForm.submitted || formGroup.get('purchase_order_id')?.touched)">
                                        <small *ngIf="formGroup.get('purchase_order_id')?.errors?.required">Please select PO Customer</small>
                                        <small *ngIf="formGroup.get('purchase_order_id')?.errors?.serverError">{{ formGroup.get("purchase_order_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" [clearable]="false" (ngModelChange)="setVendorBoq()" placeholder="-" formControlName="po_site_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('po_site_id')?.invalid && (theForm.submitted || formGroup.get('po_site_id')?.touched)" required>
                                        <ng-option *ngFor="let item of siteList" [value]="item.id">{{item.name}}</ng-option>
                                    </ng-select>
                                    <label>Site Name</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('purchase_order_id')?.invalid && (theForm.submitted || formGroup.get('po_site_id')?.touched)">
                                        <small *ngIf="formGroup.get('po_site_id')?.errors?.required">Please select Site Name</small>
                                        <small *ngIf="formGroup.get('po_site_id')?.errors?.serverError">{{ formGroup.get("purchase_order_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating mb-4">
                                    <div class="form-floating">
                                        <textarea formControlName="notes" [class.is-invalid]="formGroup.get('notes')?.invalid && (theForm.submitted || formGroup.get('notes')?.touched)" placeholder="..." class="form-control textareabox" [attr.disabled]="loading.status ? 'disabled' : null"></textarea>
                                        <label>Notes</label>
                                    </div>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('notes')?.invalid && (theForm.submitted || formGroup.get('notes')?.touched)">
                                        <small *ngIf="formGroup.get('notes')?.errors?.serverError">{{ formGroup.get("notes")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table *ngIf="details?.length" class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">#</th>
                                            <th style="width:40%;">SoW</th>
                                            <th class="text-end">Qty</th>
                                            <th>UoM</th>
                                            <th>Vendor Name</th>
                                            <th class="text-end">Vendor Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container>
                                            <tr *ngFor="let item of details;index as i;first as isFirst;last as isLast;">
                                                <td class="text-center">
                                                    {{i+1}}
                                                </td>
                                                <td>
                                                    {{item.sow_name}}
                                                </td>
                                                <td class="text-end">
                                                    {{item.qty | number}}
                                                </td>
                                                <td>
                                                    {{item.uom}}
                                                </td>
                                                <td>
                                                    <ng-select id="vendor-{{item.id}}" class="form-control" (open)="getVendor([item.vendor_boq_id])" (change)="setVendor(item)" [clearable]="false" [loading]="loading.status" placeholder="-" [(ngModel)]="item.vendor_id" [ngModelOptions]="{standalone: true}" loadingText="Loading...">
                                                        <ng-option *ngFor="let item of (vendorList | async)" [value]="item.vendor_id">{{item.vendor?.name}}</ng-option>
                                                    </ng-select>
                                                </td>
                                                <td class="text-end">
                                                    {{(item.vendor_price | number) || '-'}}
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between align-items-center border-top pt-1">
                                    <div>&nbsp;</div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                        &nbsp;
                                        <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
