import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-po-vendor-update',
    templateUrl: './po-vendor-update.component.html',
    styleUrls: ['./po-vendor-update.component.scss']
})
export class PoVendorUpdateComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
        private el: ElementRef,
        private toastr: ToastrService,
        private dateParse: DatePickerParserService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'po-vendors';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = new FormGroup({
        vendor_id: new FormControl(),
        requested_at: new FormControl(),
        deduction: new FormControl(),
        notes: new FormControl()
    });
    formChanged = false;
    details: any[] = [];
    vendorList = new BehaviorSubject<any[]>([]);
    data: any = {};
    toggle_all: any = null;

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        const details = this.details.filter((e: any) => e.checked);
        if (!details.length) {
            this.toastr.error("Please select SoW", 'Select SoW');
            return;
        }
        const emptied = details.filter((e: any) => Number(e.new_vendor_qty) < 1);
        if (emptied.length) {
            this.toastr.error("New vendor qty must be more than 0", 'Old Vendor Qty');
            this.el.nativeElement.querySelectorAll('#qty-' + emptied[0].id)?.[0]?.focus();
            return;
        }
        form.value.id = this.data.id;
        form.value.requested_at = this.dateParse.toYMD(form.value.requested_at);
        form.value.details = details;
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getVendor(): Promise<void> {
        const vendor_boq = this.details.map((e: any) => e.vendor_boq_id).join(',');
        const r = await this.http.Get('vendor-prices/' + this.data.pr_vendor?.purchase_order_id, { filter: { status: 1 }, type: 'vendors', has_vendor_boq: vendor_boq, forceView: true });
        this.vendorList.next(r.response?.result?.data || []);
    }

    toggleAll(): void {
        setTimeout(() => {
            this.details.map((e: any) => {
                e.checked = this.toggle_all;
                return e;
            })
        }, 100);
    }

    updateQty(item: any): void {
        setTimeout(() => {
            item.new_vendor_qty = Number(item.planned_qty) - Number(item.old_vendor_qty);
        }, 100);
    }

    setVendor(id: string): void {
        const vendorData = this.vendorList.getValue().find((e: any) => e.vendor_id == id) || {};
        this.details = this.details?.map((e: any) => {
            const p = vendorData.details?.find((x: any) => x.vendor_boq_id == e.vendor_boq_id) || {};
            e.vendor_price = p.price || null;
            return e;
        });
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r.response?.result?.data;
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key == 'details') {
                        this.details = r?.response?.result?.data[key]?.filter((e: any) => e.actual_qty > 0).map((e: any) => {
                            return {
                                id: e.id,
                                vendor_boq_id: e.vendor_boq_id,
                                sow_name: e.vendor_boq.sow_name,
                                uom: e.vendor_boq?.uom,
                                last_qty: e.last_qty,
                                planned_qty: e.actual_qty,
                                old_vendor_qty: 0,
                                new_vendor_qty: e.actual_qty,
                                vendor_price: null,
                            }
                        });
                    }
                });
            } else {
                this.back();
            }
        });

    }

}

