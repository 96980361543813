import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, Legend, Colors, Tooltip, BarElement, BarController, CategoryScale, LineController, LinearScale, PieController, ArcElement, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-dashboard-finance',
    templateUrl: './dashboard-finance.component.html',
    styleUrls: ['./dashboard-finance.component.scss']
})
export class DashboardFinanceComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpService,
        public loading: LoadingService
    ) {
        Chart.register(
            ChartDataLabels,
            Colors,
            Legend,
            Tooltip,
            BarElement,
            BarController,
            CategoryScale,
            LineController,
            LinearScale,
            PieController,
            ArcElement
        );
    }

    @ViewChild('barCustomer') barCustomer!: ElementRef;
    @ViewChild('barVendor') barVendor!: ElementRef;
    private apiPath = 'dashboard';
    barCustomerChart!: any;
    barVendorChart!: any;
    data: any = {};
    poList: any = [];
    selectedCustomerPo: string = '';
    selectedVendorPo: string = '';
    progressVendorLoading = true;
    progressCustomerLoading = true;
    chartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                formatter: (value: number) => {
                    return formatNumber(value, 'en');;
                }
            },
        }
    };

    async getPo(): Promise<void> {
        const r = await this.http.Get('purchase-orders', { forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getCustomerData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedCustomerPo = '';
        }
        this.progressCustomerLoading = true;
        const r = await this.http.Get(this.apiPath + '/finance-customer', { po_id: (this.selectedCustomerPo || null), forceView: true }, progressOnly);
        this.data = r.response?.result || {};

        this.barCustomerChart?.destroy();
        let barProgressCustomerData: ChartData<'bar'> = { labels: this.data?.labels, datasets: this.data?.datasets };
        this.barCustomerChart = new Chart(this.barCustomer.nativeElement, {
            type: 'bar',
            data: barProgressCustomerData,
            options: this.chartOptions
        });

        this.progressCustomerLoading = false;
    }

    async getVendorData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedVendorPo = '';
        }
        this.progressVendorLoading = true;
        const r = await this.http.Get(this.apiPath + '/finance-vendor', { po_id: (this.selectedVendorPo || null), forceView: true }, progressOnly);
        this.data = r.response?.result || {};

        this.barVendorChart?.destroy();
        let barProgressVendorData: ChartData<'bar'> = { labels: this.data?.labels, datasets: this.data?.datasets };
        this.barVendorChart = new Chart(this.barVendor.nativeElement, {
            type: 'bar',
            data: barProgressVendorData,
            options: this.chartOptions
        });

        this.progressVendorLoading = false;
    }

    async getData(): Promise<void> {
        this.progressCustomerLoading = true;
        this.progressVendorLoading = true;
        await this.getCustomerData();
        this.getVendorData();
    }

    ngOnInit(): void {
        this.getPo();
    }

    ngAfterViewInit(): void {
        this.getData();
    }
}
