import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/services/notification.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PageQueryService } from 'src/app/services/page-query.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

    constructor(
        public pageQuery: PageQueryService,
        public loading: LoadingService,
        private http: HttpService,
        private modalService: NgbModal,
        private notif: NotificationService,
        private router: Router
    ) {
        this.loading.start();
    }

    @ViewChild('deleteModal') deleteModal: any;
    @ViewChild('readModal') readModal: any;
    private apiPath = 'notifications';
    data: any = {};

    deleteDialog: any = {
        ids: [],
        isDeleting: false,
        modal: null,
        show: (ids: Array<any>) => {
            this.deleteDialog.ids = ids;
            this.deleteDialog.modal = this.modalService.open(this.deleteModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            this.deleteDialog.isDeleting = true;
            let fdata = new FormData();
            this.http.Post(this.apiPath + '/delete', fdata).then((r: any) => {
                this.deleteDialog.isDeleting = false;
                if (r.success) {
                    this.notif.read();
                    this.getData();
                    this.deleteDialog.modal.close();
                }
            });
        }
    }

    readDialog: any = {
        isLoading: false,
        modal: null,
        show: () => {
            this.readDialog.modal = this.modalService.open(this.readModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            this.readDialog.isLoading = true;
            let fdata = new FormData();
            this.http.Post(this.apiPath + '/read', fdata).then((r: any) => {
                this.readDialog.isLoading = false;
                if (r.success) {
                    this.notif.read();
                    this.getData();
                    this.readDialog.modal.close();
                }
            });
        }
    }

    gotoUrl(item: any): void {
        this.notif.read(item.id);
        this.router.navigate([item.url]);
    }

    getData(): void {
        const query: any = this.pageQuery.query.getValue() || {};
        query.limit = 10;
        query.page = query?.page || 1;
        this.http.Get(this.apiPath, query).then((r: any) => {
            if (r.success) {
                this.data = r?.response?.result || {};
            }
        });
    }

    ngOnInit(): void {
        this.pageQuery.init(() => {
            this.getData();
        });
    }

    ngOnDestroy(): void {
        this.pageQuery.destroy();
    }
}
