<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Add Project Category</h4>
                    <form #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-md-8">
                        <div [formGroup]="formGroup">
                            <div class="form-floating mb-4">
                                <ng-select class="form-control" placeholder="-" formControlName="project_type_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('project_type_id')?.invalid && (theForm.submitted || formGroup.get('project_type_id')?.touched)" required>
                                    <ng-option *ngFor="let item of projectTypeList" [value]="item.id">{{item.code}} - {{item.name}}</ng-option>
                                </ng-select>
                                <label class="required">Project Type</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('project_type_id')?.invalid && (theForm.submitted || formGroup.get('project_type_id')?.touched)">
                                    <small *ngIf="formGroup.get('project_type_id')?.errors?.required">Please select project type</small>
                                    <small *ngIf="formGroup.get('project_type_id')?.errors?.serverError">{{ formGroup.get("project_type_id")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="name" [class.is-invalid]="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)" placeholder="..." class="form-control" required>
                                <label>Name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)">
                                    <small *ngIf="formGroup.get('name')?.errors?.required">Please type name</small>
                                    <small *ngIf="formGroup.get('name')?.errors?.serverError">{{ formGroup.get("name")?.errors?.serverError }}</small>
                                </div>
                            </div>
                            <div class="form-check form-switch mb-4">
                                <input class="form-check-input" type="checkbox" formControlName="status" id="flexSwitchCheckDefault" />
                                <label class="form-check-label" for="flexSwitchCheckDefault">Status : <strong>{{ formGroup.get("status")?.value ? "Active" : "Inactive" }}</strong></label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-top pt-1">
                            <div>&nbsp;</div>
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                &nbsp;
                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
