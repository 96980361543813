import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UomService } from 'src/app/services/uom.service';

@Component({
    selector: 'app-po-additional-price',
    templateUrl: './po-additional-price.component.html',
    styleUrls: ['./po-additional-price.component.scss']
})
export class PoAdditionalPriceComponent implements OnInit {

    constructor(
        private http: HttpService,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private el: ElementRef,
        private uom: UomService,
        private toastr: ToastrService
    ) { }
    private apiPath = 'po-additional-price';
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = new FormGroup({
        id: new FormControl('1'),
        item_name: new FormControl(),
        uom: new FormControl(),
        price: new FormControl(),
    });
    formChanged = false;
    uomList = this.uom.getUoMList();
    isEdit = false;
    data: any = {};

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }

        form.value.status = Number(form.value.status);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.PostFile(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.ngOnInit();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath, {}).then((r: any) => {
            if (!r.success) {
                this.toastr.error('Something went wrong', 'Oops');
                this.formGroup.disable({ emitEvent: false });
                return;
            }
            const data = r.response?.result?.data || {};
            this.data = data;
            this.formGroup.get('item_name')?.setValue(data.item_name);
            this.formGroup.get('uom')?.setValue(data.uom);
            this.formGroup.get('price')?.setValue(data.price);

            const oldValue = this.formGroup.value;
            this.formGroup.valueChanges.subscribe((e: any) => {
                this.formChanged = (oldValue != e);
            });
        });
    }

}
