import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PageQueryService } from 'src/app/services/page-query.service';

@Component({
    selector: 'app-vendor-price-vendor-list',
    templateUrl: './vendor-price-vendor-list.component.html',
    styleUrls: ['./vendor-price-vendor-list.component.scss']
})
export class VendorPriceVendorListComponent implements OnInit, OnDestroy {
    constructor(
        private location: Location,
        private aRoute: ActivatedRoute,
        public pageQuery: PageQueryService,
        public loading: LoadingService,
        private http: HttpService,
        private modalService: NgbModal,
        private toastr: ToastrService
    ) {
        this.loading.start();
        this.aRoute.params.subscribe(e => {
            this.id = e.id;
        });
    }

    @ViewChild('deleteModal') deleteModal: any;
    @ViewChild('formModal') formModal: any;
    private apiPath = 'vendor-prices';
    id: any;
    poData: any;
    data: any = {};
    permission: any = {};
    vendorList: any[] = [];

    deleteDialog: any = {
        ids: [],
        isDeleting: false,
        modal: null,
        show: (ids: Array<any>) => {
            this.deleteDialog.ids = ids;
            this.deleteDialog.modal = this.modalService.open(this.deleteModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            this.deleteDialog.isDeleting = true;
            let urlParameters = Object.entries(this.pageQuery.query.getValue()).filter(k => { return k[1] != null }).map(e => e.join('=')).join('&');
            this.http.Delete(this.apiPath + '/delete?' + urlParameters, this.deleteDialog.ids).then((r: any) => {
                this.deleteDialog.isDeleting = false;
                if (r.success) {
                    this.data = r?.response?.result || {};
                    this.deleteDialog.modal.close();
                }
            })
        }
    }

    formDialog: any = {
        isLoading: false,
        modal: null,
        data: {},
        setItem: (id: any, i: number) => {
            const item = this.data.po_data.vendor_boqs.find((i: any) => i.id == id) || {};
            this.formDialog.data.details[i].vendor_boq = item;
        },
        show: async (data?: any) => {
            await this.getVendor();
            this.formDialog.data = data || { details: [{}] };
            this.formDialog.modal = this.modalService.open(this.formModal, { keyboard: false, backdrop: 'static', centered: true, size: 'lg' });
        },
        submit: () => {
            if (!this.formDialog.data?.vendor_id) {
                this.toastr.error('Please Select Vendor', 'Select Vendor');
                (<any>document.querySelector('#vendor-id input'))?.focus();
                return;
            }
            const hasNoSow = this.formDialog.data.details.findIndex((e: any) => !e.vendor_boq_id);
            if (hasNoSow > -1) {
                this.toastr.error("Please select Vendor SoW", 'Select SoW');
                (<any>document.querySelector('#sow_' + hasNoSow))?.focus();
                return;
            }
            const hasNoPrice = this.formDialog.data.details.findIndex((e: any) => !e.price);
            if (hasNoPrice > -1) {
                this.toastr.error("Please type Price", 'Type Price');
                (<any>document.querySelector('#price_' + hasNoPrice))?.focus();
                return;
            }
            const exceedPrice = this.formDialog.data.details.findIndex((e: any) => e.vendor_boq?.price && (e.price > e.vendor_boq?.price));
            if (exceedPrice > -1) {
                this.toastr.error("Vendor Price must not exceed the Target Price", 'Maximum Price');
                (<any>document.querySelector('#price_' + exceedPrice))?.focus();
                return;
            }
            this.formDialog.isLoading = true;
            this.formDialog.data.purchase_order_id = this.id;
            let fdata = new FormData();
            fdata.append('data', JSON.stringify(this.formDialog.data));
            this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
                this.formDialog.isLoading = false;
                if (r.success) {
                    this.getData();
                    this.toastr.success('Data saved successfully', 'Success');
                    this.formDialog.data = {};
                    this.formDialog.modal.close();
                }
            });
        }
    }

    setStatus(item: any): void {
        const old_status = item.status;
        item.status = item.status ? 0 : 1;
        this.http.Post(this.apiPath + '/set-status', { id: item.id, status: item.status }).then((r: any) => {
            if (!r.success) {
                item.status = old_status;
                return;
            }
            this.toastr.success('Changes saved successfully');
        });
    }


    getData(): void {
        const query: any = this.pageQuery.query.getValue() || {};
        query.limit = query?.limit || 10;
        query.page = query?.page || 1;
        query.filter = { status: 1 };
        query.type = 'vendors';
        this.http.Get(this.apiPath + '/' + this.id, query).then((r: any) => {
            if (r.success) {
                this.data = r?.response?.result || {};
                this.permission = r?.permission || {};
            }
        });
    }

    async getVendor(): Promise<void> {
        const r = await this.http.Get('vendors', { filter: { status: 1, type: 'services' }, forceView: true });
        this.vendorList = r.response?.result?.data || [];
    }

    back(): void {
        this.location.back();
    }


    ngOnInit(): void {
        this.pageQuery.init(() => {
            this.getData();
        });
    }
    ngOnDestroy(): void {
        this.pageQuery.destroy();
    }

}
