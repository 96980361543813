<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Back">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Material Item</h4>
                    </div>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="data.id" class="col-md-12 mt-3">
                        <ul ngbNav #nav="ngbNav" class="nav-tabs">
                            <li ngbNavItem>
                                <a ngbNavLink>Material Detail</a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-8 mb-3">
                                            <div class="form-floating mb-4">
                                                <input [value]="data.code" placeholder="..." class="form-control" readonly>
                                                <label>Code</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.name" placeholder="..." class="form-control" readonly>
                                                <label>Name</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.uom" placeholder="..." class="form-control" readonly>
                                                <label>UoM</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.status==1?'Active':'Inactive'" placeholder="..." class="form-control" readonly>
                                                <label>Status</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.created_at | dateFormat" placeholder="..." class="form-control" readonly>
                                                <label>Created at</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.creator?.name || '-'" placeholder="..." class="form-control" readonly>
                                                <label>Created By</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="(data.last_updated_at | dateFormat) || '-'" placeholder="..." class="form-control" readonly>
                                                <label>Last Updated at</label>
                                            </div>
                                            <div class="form-floating mb-4">
                                                <input type="text" [value]="data.updater?.name ||'-'" placeholder="..." class="form-control" readonly>
                                                <label>Last Updated By</label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="w-100">
                                                <div style="height: 200px" [ngClass]="{'bg-secondary bg-opacity-10': !data.image}" [ngStyle]="data.image && {background: 'url(' + assetUrl+data.image + ') center no-repeat', 'background-size': 'contain'}" class="border rounded d-flex justify-content-center align-items-center position-relative flex-wrap">
                                                    <div *ngIf="!data.image">
                                                        {{ "No image" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink (click)="getPrice()">Price List</a>
                                <ng-template ngbNavContent>
                                    <div class="md-3">
                                        <table>
                                            <tr>
                                                <td>Material Code</td>
                                                <td>:</td>
                                                <td>{{data.code}}</td>
                                            </tr>
                                            <tr>
                                                <td>Material Name</td>
                                                <td>:</td>
                                                <td>{{data.name}}</td>
                                            </tr>
                                        </table>
                                        <div class="text-center h4 p-3" *ngIf="!prices?.length">
                                            <div *ngIf="loading.status">
                                                <div class="spinner-grow" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                            <div *ngIf="!loading.status">No data found..</div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-hover" *ngIf="prices?.length">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">#</th>
                                                        <th>Supplier</th>
                                                        <th class="text-end">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of prices; index as i">
                                                        <td class="text-center">
                                                            {{ i+1 }}
                                                        </td>
                                                        <td>
                                                            {{ item.supplier_price?.vendor?.name }}
                                                        </td>
                                                        <td class="text-end">
                                                            {{ item.price | number }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
