<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 d-md-flex align-items-center mb-4">
                        <h4 class="card-title me-2 mb-md-0 mb-3">Report Supplier Price</h4>
                    </div>
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center border-top pt-3">
                            <div>
                                <button type="button" (click)="isDownload=false;submitForm();" class="btn btn-secondary me-3" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <i class="material-icons">list</i> &nbsp;
                                    View
                                </button>
                                <button type="button" (click)="isDownload=true;submitForm();" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <i class="material-icons">file_download</i> &nbsp;
                                    Download
                                </button>
                            </div>
                            <div class="text-end">
                                <div class="spinner-border text-primary" role="status" *ngIf="submitLoading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="data.data?.length" class="row">
    <div class="col-md-12 mt-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th rowspan="2">Material Code</th>
                                        <th rowspan="2">Material Name</th>
                                        <th rowspan="2">UoM</th>
                                        <th [colSpan]="data.supplier_list?.length" class="text-center">
                                            Suppliers
                                        </th>
                                    </tr>
                                    <tr>
                                        <th *ngFor="let v of data.supplier_list" class="text-end">{{v.supplier_price?.vendor?.name}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data?.data; let i = index">
                                        <td>
                                            {{ item.code }}
                                        </td>
                                        <td>
                                            {{ item.name }}
                                        </td>
                                        <td>{{ item.uom }}</td>
                                        <td *ngFor="let v of data.supplier_list" class="text-end">
                                            {{(findPrice(item.id)?.price | number) || '-'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
