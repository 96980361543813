import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UomService } from 'src/app/services/uom.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-material-edit',
    templateUrl: './material-edit.component.html',
    styleUrls: ['./material-edit.component.scss']
})
export class MaterialEditComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private el: ElementRef,
        private uom: UomService,
        private fb: FormBuilder,
        private toastr: ToastrService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'materials';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = this.fb.group({});
    formChanged = false;
    image: any = {};
    uomList = this.uom.getUoMList();

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }

        form.value.status = Number(form.value.status);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        if (this.image?.file) {
            fdata.append('image', this.image.file);
        }
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.PostFile(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key != 'image') {
                        this.formGroup.addControl(key, new FormControl(r?.response?.result?.data[key]));
                    }
                });
                if (r?.response?.result?.data.image) {
                    this.image.view = environment.assetUrl + r?.response?.result?.data.image;
                }

                const oldValue = this.formGroup.value;
                this.formGroup.valueChanges.subscribe((e: any) => {
                    this.formChanged = (oldValue != e);
                });
            } else {
                this.back();
            }
        });
    }

}
