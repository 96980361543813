import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, Legend, Colors, Tooltip, BarElement, BarController, CategoryScale, LineController, LinearScale, PieController, ArcElement, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-dashboard-vendor',
    templateUrl: './dashboard-vendor.component.html',
    styleUrls: ['./dashboard-vendor.component.scss']
})
export class DashboardVendorComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpService,
        public loading: LoadingService
    ) {
        Chart.register(
            ChartDataLabels,
            Colors,
            Legend,
            Tooltip,
            BarElement,
            BarController,
            CategoryScale,
            LineController,
            LinearScale,
            PieController,
            ArcElement
        );
    }

    @ViewChild('barProgress') barProgress!: ElementRef;
    @ViewChild('barVendor') barVendor!: ElementRef;
    private apiPath = 'dashboard';
    barProgressChart!: any;
    barVendorChart!: any;
    data: any = {};
    poList: any = [];
    selectedPo: string = '';
    selectedVendorPo: string = '';
    progressVendorLoading = true;
    progressLoading = true;

    async getPo(): Promise<void> {
        const r = await this.http.Get('purchase-orders', { forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getTopData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedPo = '';
        }
        this.progressLoading = true;
        const r = await this.http.Get(this.apiPath + '/vendor', { po_id: (this.selectedPo || null), forceView: true }, progressOnly);
        this.data = r.response?.result || {};

        this.barProgressChart?.destroy();
        let barProgressData: ChartData<'bar'> = { labels: [], datasets: [] };
        this.data.progress?.forEach((e: any) => {
            let data: any = [];
            e.data.forEach((d: any) => {
                barProgressData?.labels?.push(d.name);
                data.push(d.total);
            })
            barProgressData.datasets.push({ label: e.name, data: data });
        });
        this.barProgressChart = new Chart(this.barProgress.nativeElement, {
            type: 'bar',
            data: barProgressData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return value + '%';
                        }
                    },
                }
            }
        });
        this.progressLoading = false;
    }

    async getVendorData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedVendorPo = '';
        }
        this.progressVendorLoading = true;
        const r = await this.http.Get(this.apiPath + '/vendor-doc', { po_id: (this.selectedVendorPo || null), forceView: true }, progressOnly);
        const data = r.response?.result || {};

        this.barVendorChart?.destroy();
        let barProgressVendorData: ChartData<'bar'> = { labels: data?.labels, datasets: data?.datasets };
        this.barVendorChart = new Chart(this.barVendor.nativeElement, {
            type: 'bar',
            data: barProgressVendorData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return value + '%';
                        }
                    },
                }
            }
        });

        this.progressVendorLoading = false;
    }

    async getData(): Promise<void> {
        this.progressLoading = true;
        this.progressVendorLoading = true;
        await this.getTopData();
        this.getVendorData();
    }

    ngOnInit(): void {
        this.getPo();
    }

    ngAfterViewInit(): void {
        this.getData();
    }
}
