<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex justify-content-between row">
                    <div class="row pe-0">
                        <div class="d-flex flex-nowrap align-items-center col-md-6 mb-3">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Detail PO Supplier</h4>
                        </div>
                    </div>
                    <!-- <div *ngIf="data.id" class="d-none justify-content-center col-12">
                        <div class="text-center d-flex justify-content-center flex-wrap step-container active">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span class="material-icons">verified_user</span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    Created
                                </div>
                                <div class="fw-bold">
                                    {{data.creator?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let app of data.approvals;" [class.reject]="app.status==-1" [class.active]="app.status==1" class="text-center d-flex justify-content-center flex-wrap step-container">
                            <div style="width: 32px; height: 32px" class="bg-gray rounded-circle text-white d-flex justify-content-center align-items-center icon-step">
                                <span *ngIf="app.status==0" class="material-icons">
                                    hourglass_top
                                </span>
                                <span *ngIf="app.status==1" class="material-icons">
                                    verified_user
                                </span>
                                <span *ngIf="app.status==-1" class="material-icons">
                                    gpp_bad
                                </span>
                            </div>
                            <div class="w-100 text-center small">
                                <div class="text-muted">
                                    <span *ngIf="app.status==1">
                                        {{app.level_name=='approval' ? 'Approved' : 'Allocated'}}
                                    </span>
                                    <span *ngIf="app.status==-1">
                                        Rejected
                                    </span>
                                    <span *ngIf="app.status==0">
                                        Waiting {{app.level_name || 'Unknown'}}
                                    </span>
                                </div>
                                <div class="fw-bold">
                                    {{app?.role?.name || 'Unknown'}}
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div *ngIf="!data.id" class="text-center">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div *ngIf="data.id" class="mt-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled type="text" [value]="data.requested_at | dateFormat:'DD MMM YYYY'" placeholder="..." class="form-control">
                                        <label>Request Date</label>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.creator?.name" placeholder="..." class="form-control">
                                        <label>Request By</label>
                                    </div>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.pr_supplier?.contract?.project_name" placeholder="..." class="form-control">
                                        <label>Project Name</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.pr_supplier?.purchase_order?.number" placeholder="..." class="form-control">
                                        <label>PO Customer</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input disabled [value]="data.pr_supplier?.number" placeholder="..." class="form-control">
                                        <label>PR Number</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.number" placeholder="..." class="form-control">
                                        <label>PO Number</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" disabled [value]="data.vendor?.name" placeholder="..." class="form-control">
                                        <label>Supplier</label>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-floating mb-4">
                                        <textarea disabled [value]="data.pr_supplier?.notes" placeholder="..." class="form-control textareabox"></textarea>
                                        <label>Notes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- <div *ngIf="data.status==1" class="d-flex align-items-center">
                                <div>Approved at : <strong>{{data.closed_at | dateFormat:'DD MMM YYYY'}}</strong></div>
                            </div> -->
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Material
                                            </th>
                                            <th class="text-end">
                                                Qty
                                            </th>
                                            <th>
                                                UoM
                                            </th>
                                            <th class="text-end">
                                                Unit Price
                                            </th>
                                            <th class="text-end">
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.details;index as i">
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{item.material?.code}} - {{item.material?.name}}
                                            </td>
                                            <td class="text-end">
                                                {{item.qty | number}}
                                            </td>
                                            <td>
                                                {{item.material?.uom}}
                                            </td>
                                            <td class="text-end">
                                                {{item.price | number}}
                                            </td>
                                            <td class="text-end">
                                                {{(item.total | number)}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="rejectRemarks()" class="row">
                        <div class="col-12">
                            <div class="alert alert-danger mb-4">
                                <div class="fw-bold">Rejection remarks :</div>
                                <div>{{rejectRemarks()}}</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <!-- <div *ngIf="data.need_approval" class="col-12 text-end">
                            <div *ngIf="myApproval().level_name=='approval'">
                                <button class="btn btn-danger me-2" (click)="rejectDialog.show()" [disabled]="loading.status">
                                    &nbsp;&nbsp;<span class="material-icons">gpp_bad</span>&nbsp;Reject&nbsp;&nbsp;
                                </button>
                                <button class="btn btn-primary" (click)="approveDialog.show();" [disabled]="loading.status">
                                    &nbsp;&nbsp;<span class="material-icons">verified_user</span>&nbsp;Approve&nbsp;&nbsp;
                                </button>
                            </div>
                        </div> -->
                        <div *ngIf="data.status==1" class="col-12 text-end">
                            <button class="btn btn-primary me-2" [disabled]="loading.status" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
                                &nbsp;&nbsp;<span class="material-icons">print</span>&nbsp;Print&nbsp;&nbsp;
                            </button>
                        </div>
                    </div>

                    <!-- <div class="row mt-3 d-none">
                        <div class="col-12 small border-top pt-2">
                            <em class="fw-bold">Approval History</em>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>User</th>
                                            <th>Timestamp</th>
                                            <th>Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                                {{data.creator?.role?.name || '-'}}
                                            </td>
                                            <td>
                                                Created
                                            </td>
                                            <td class="text-capitalize">
                                                {{data.creator?.name || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{(data.created_at | dateFormat:'DD MMM YYYY, HH:mm') || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                -
                                            </td>
                                        </tr>
                                        <tr *ngFor="let app of data.approvals | filter:'status':1:'status':-1;index as i;">
                                            <td>{{i+2}}</td>
                                            <td>
                                                {{app.role?.name || '-'}}
                                            </td>
                                            <td>
                                                <span *ngIf="app.status==1">
                                                    Approved
                                                </span>
                                                <span *ngIf="app.status==-1">
                                                    Rejected
                                                </span>
                                                <span *ngIf="app.status==0">
                                                    Waiting
                                                </span>
                                            </td>
                                            <td class="text-capitalize">
                                                {{app.updater?.name || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{(app.updated_at | dateFormat:'DD MMM YYYY, HH:mm') || '-'}}
                                            </td>
                                            <td class="text-capitalize">
                                                {{app.remarks || '-'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #approveModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Approve</h5>
    </div>
    <div class="modal-body">Are you sure want to <strong>Approve</strong> this data?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-primary" (click)="approveDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Approve</button>
        </div>
    </div>
</ng-template>

<ng-template #rejectModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Reject</h5>
    </div>
    <div class="modal-body">
        <div>Are you sure want to <strong>Reject</strong> this data?</div>
        <div class="form-floating mt-3">
            <textarea [(ngModel)]="rejectDialog.remarks" placeholder="..." class="form-control" required></textarea>
            <label>Please type remarks</label>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="rejectDialog.submit()" [attr.disabled]="loading.status || !rejectDialog.remarks ? 'disabled' : null">Reject</button>
        </div>
    </div>
</ng-template>

<div class="d-none">
    <div *ngIf="data.id && data.status==1" id="print-section" class="row bg-light">
        <div class="col-12 text-center mb-3 d-flex position-relative justify-content-between">
            <div>
                <div class="position-absolute top-0 start-0">
                    <img src="/assets/logo.png" style="width:150px;height:auto;">
                </div>
            </div>
            <div>
                <h2>SRP</h2>
                <h3>PURCHASE ORDER</h3>
            </div>
            <div>
                <div class="qr-img position-absolute top-0 end-0" style="margin-top: -10px;"></div>
            </div>
        </div>
        <div class="col-12 mb-3">
            <table class="table table-bordered" cellpadding="5">
                <tr>
                    <th>PO Date</th>
                    <td>
                        {{data.requested_at | dateFormat:'DD MMM YYYY'}}
                    </td>
                    <th>PO Number</th>
                    <td>
                        {{data.number}}
                    </td>
                </tr>
                <tr>
                    <th>Supplier</th>
                    <td>
                        {{data.vendor?.name}}
                    </td>
                    <th>Printed by</th>
                    <td>
                        {{''|me}} at {{''|now}}
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-12 mb-3">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Material Code
                        </th>
                        <th>
                            Material Name
                        </th>
                        <th>
                            UoM
                        </th>
                        <th class="text-end">
                            Quantity
                        </th>
                        <th class="text-end">
                            Unit Price (IDR)
                        </th>
                        <th class="text-end">
                            Total Price (IDR)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of data.details;index as i">
                        <tr>
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{item.material?.code}}
                            </td>
                            <td>
                                {{item.material?.name}}
                            </td>
                            <td>
                                {{item.material?.uom}}
                            </td>
                            <td class="text-end">
                                {{item.qty | number}}
                            </td>
                            <td class="text-end">
                                {{item.price | number}}
                            </td>
                            <td class="text-end">
                                {{item.total | number}}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <!-- <div [class.justify-content-start]="data.approvals.length>5" [class.justify-content-between]="data.approvals.length<=5" class="row m-0 d-flex flex-wrap"> -->
            <!-- <div class="col-3 border text-center mb-3 p-1">
                <div>
                    <strong>Requested/Created By</strong>
                </div>
                <div class="text-muted">
                    {{data.creator?.role?.name || '-'}}
                </div>
                <div>
                    {{data.creator?.name || '-'}}
                </div>
                <div>
                    {{data.created_at | dateFormat:'DD MMM YYYY'}}
                </div>
            </div> -->
            <!-- <div *ngFor="let app of data.approvals" class="col-3 border text-center mb-3 p-1">
                <div>
                    <strong>
                        Approved By
                    </strong>
                </div>
                <div class="text-muted">
                    {{app.role?.name || '-'}}
                </div>
                <div>
                    {{app.updater?.name || '-'}}
                </div>
                <div>
                    {{app.updated_at | dateFormat:'DD MMM YYYY'}}
                </div>
            </div> -->
        <!-- </div> -->
    </div>
</div>
