import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-po-additional-add',
    templateUrl: './po-additional-add.component.html',
    styleUrls: ['./po-additional-add.component.scss']
})
export class PoAdditionalAddComponent implements OnInit {

    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public loading: LoadingService,
        private dateParse: DatePickerParserService,
        private toastr: ToastrService) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'po-additionals';
    stayPage: boolean = false;
    formGroup = new FormGroup({
        vendor_id: new FormControl(),
        contract_id: new FormControl(),
        purchase_order_id: new FormControl(),
        po_site_id: new FormControl(),
        requested_at: new FormControl(),
        notes: new FormControl()
    });
    formChanged = false;
    contractList: any[] = [];
    poList: any[] = [];
    siteList: any[] = [];
    details: any[] = [];
    vendorList = new BehaviorSubject<any[]>([]);

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        if (!this.details.length) {
            this.toastr.error("PO Additional items can't be blank", 'Error');
            return;
        }
        const emptied = this.details.filter((e: any) => Number(e.qty) <= 0);
        if (emptied.length) {
            this.toastr.error("Please type Qty", 'Error');
            this.el.nativeElement.querySelectorAll('#item-qty')?.[0]?.focus();
            return;
        }
        form.value.qty = this.details[0]?.qty;
        form.value.requested_at = this.dateParse.toYMD(form.value.requested_at);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    async getPO(): Promise<void> {
        const contract_id = this.formGroup.get('contract_id')?.value;
        if (!contract_id) {
            this.formGroup.get('purchase_order_id')?.reset();
            this.formGroup.get('po_site_id')?.reset();
        }
        const r = await this.http.Get('purchase-orders', { filter: { contract_id: contract_id, status: 1 }, vendor_boq: 1, forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getSite(): Promise<void> {
        const po_id = this.formGroup.get('purchase_order_id')?.value;
        if (!po_id) {
            this.formGroup.get('po_site_id')?.reset();
        }
        const r = await this.http.Get('sites/' + po_id, { forceView: true });
        this.siteList = r.response?.result?.data || [];
    }

    async getVendor(): Promise<void> {
        this.vendorList.next([]);
        const r = await this.http.Get('vendors', { filter: { status: 1, type: 'services' }, forceView: true });
        this.vendorList.next(r.response?.result?.data || []);
    }

    async setVendorBoq(): Promise<void> {
        const r = await this.http.Get('po-additional-price', { forceView: true });
        const data = r.response?.result?.data || {};
        if (!data.id) {
            this.toastr.error("PO Additional price is not found, please set it first", 'Set PO Additional Price');
            return;
        }
        this.details.push({
            sow_name: data.item_name,
            qty: 1,
            uom: data.uom,
            price: data.price,
            total: (1 * data.price)
        });
    }

    setTotal(item: any): void {
        item.total = Number(item.qty) * item.price;
    }

    ngOnInit(): void {
        this.getContract();
        this.getVendor();
        this.setVendorBoq();
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
    }

}
