<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center" *ngIf="!data.po_data?.id">
                            <div class="spinner-grow" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="data.po_data?.id" class="row">
                    <div class="col-12 border-bottom mb-3">
                        <div class="d-flex flex-nowrap align-items-center">
                            <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Back">
                                <i class="material-icons">arrow_back</i>
                            </button>
                            <h4 class="card-title col-12 lh-0 m-0">Vendor List</h4>
                        </div>
                        <div class="mt-3">
                            <table class="table" cellpadding="5">
                                <tr>
                                    <td style="width:15%;" nowrap>Project Name</td>
                                    <td style="width:2%;" nowrap>:</td>
                                    <td>{{data.po_data?.contract?.project_name}}</td>
                                </tr>
                                <tr>
                                    <td style="width:15%;" nowrap>Project Category</td>
                                    <td style="width:2%;" nowrap>:</td>
                                    <td>{{data.po_data?.contract?.project_category?.name}}</td>
                                </tr>
                                <tr>
                                    <td style="width:15%;" nowrap>PO Number</td>
                                    <td style="width:2%;" nowrap>:</td>
                                    <td>{{data.po_data?.number}}</td>
                                </tr>
                                <tr>
                                    <td style="width:15%;" nowrap>Customer</td>
                                    <td style="width:2%;" nowrap>:</td>
                                    <td>{{data.po_data?.contract?.customer_name}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 d-flex justify-content-end">
                        <div *ngIf="!pageQuery.isSearch">
                            <button *ngIf="permission.create" (click)="formDialog.show()" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"> Create </button>
                            <button title="Refresh" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary ms-1" (click)="getData()">
                                <i class="material-icons">refresh</i>
                            </button>
                            <button title="Search" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary ms-1" (click)="pageQuery.isSearch = true">
                                <i class="material-icons">search</i>
                            </button>
                        </div>
                        <div *ngIf="pageQuery.isSearch" class="w-100">
                            <div class="input-group has-search">
                                <span class="input-group-text pt-0 pb-0 bg-white border-secondary">
                                    <i class="material-icons">search</i>
                                </span>
                                <input type="search" [appAutoFocus]="true" [(ngModel)]="pageQuery.queryPage.q" (keyup)="pageQuery.searchData($event)" class="form-control border-start-0 ps-1 shadow-none border-secondary bg-light" placeholder="Type & Hit Enter" />
                                <button title="Search" class="btn btn-secondary" [attr.disabled]="loading.status ? 'disabled' : null" (click)="pageQuery.changeRoute({ q: '' }); pageQuery.isSearch = false">
                                    <i class="material-icons">close</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="data.po_data?.id" class="row pt-2">
                    <div class="col-12">
                        <div class="text-center h4 p-3" *ngIf="!data?.data?.length">
                            <div *ngIf="loading.status">
                                <div class="spinner-grow" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!loading.status">No vendor list found..</div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover" *ngIf="data?.data?.length">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Vendor Name</th>
                                        <th>Vendor PIC</th>
                                        <th>Contact Number</th>
                                        <th *ngIf="permission.update">Status</th>
                                        <th>Created at</th>
                                        <th class="text-center">
                                            <i class="material-icons">more_vert</i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data?.data; let i = index">
                                        <td class="text-center">
                                            {{ data.from + i }}
                                        </td>
                                        <td>
                                            {{ item.vendor?.name }}
                                        </td>
                                        <td>{{ item.vendor?.pic }}</td>
                                        <td>{{ item.vendor?.contact_number }}</td>
                                        <td *ngIf="permission.update" nowrap>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" [disabled]="loading.status" type="checkbox" id="status_{{ i }}" (change)="setStatus(item)" [attr.checked]="item.status == 1 ? 'checked' : null" />
                                                <label class="form-check-label" for="status_{{ i }}">{{ item.status ? "Active" : "Inactive" }}</label>
                                            </div>
                                        </td>
                                        <td nowrap>
                                            {{ item.created_at | dateFormat : "DD MMM YYYY, HH:mm" }}
                                        </td>
                                        <td class="text-center" nowrap>
                                            <div class="d-flex nav-item justify-content-center" ngbDropdown display="dynamic" placement="bottom-right" container="body">
                                                <button class="btn btn-sm text-primary border-primary border-1 dropdown-toggle" ngbDropdownToggle>Action</button>
                                                <ul ngbDropdownMenu>
                                                    <li *ngIf="permission.update">
                                                        <button class="dropdown-item btn-link d-flex align-items-center" [class.disabled]="loading.status" title="Edit" (click)="formDialog.show(item)">
                                                            <i class="material-icons fs-5 me-3">edit</i>
                                                            Edit
                                                        </button>
                                                    </li>
                                                    <li *ngIf="permission.delete">
                                                        <button [attr.disabled]="loading.status ? 'disabled' : null" class="dropdown-item btn-link d-flex align-items-center" title="Delete" (click)="deleteDialog.show([item.id])">
                                                            <i class="material-icons fs-5 me-3">delete</i>
                                                            Delete
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="data?.data?.length" class="d-flex justify-content-between row small">
                            <div class="col-md-2 small d-flex flex-nowrap row align-items-center">
                                <div class="col-5 d-flex align-items-center">Limit :</div>
                                <select style="height: 25px;" class="form-select form-select-sm col-6 w-50" [attr.disabled]="loading.status ? 'disabled' : null" [(ngModel)]="pageQuery.queryPage.limit" (change)="pageQuery.changeRoute({limit:pageQuery.queryPage.limit});">
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <div class="col-md-4 d-flex flex-nowrap align-items-center justify-content-center justify-content-md-end">
                                <div class="text-nowrap">
                                    Total: {{data.total || 0}}
                                </div>
                                <div class="text-nowrap">
                                    <button (click)="pageQuery.changeRoute({page:1});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page===1 || !data.prev_page_url ? 'disabled' : null">
                                        <span class="material-icons">first_page</span>
                                    </button>
                                    <button (click)="pageQuery.changeRoute({page:(data.prev_page_url).split('page=')[1]});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page===1 || !data.prev_page_url ? 'disabled' : null">
                                        <span class="material-icons">navigate_before</span>
                                    </button>
                                    <span>
                                        {{data.current_page || 1}}/{{data.last_page || '&infintie;'}}
                                    </span>
                                    <button (click)="pageQuery.changeRoute({page:(data.next_page_url).split('page=')[1]});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page===data.last_page ? 'disabled' : null">
                                        <span class="material-icons">navigate_next</span>
                                    </button>
                                    <button (click)="pageQuery.changeRoute({page:data.last_page});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page===data.last_page || !data.last_page ? 'disabled' : null">
                                        <span class="material-icons">last_page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete</h5>
    </div>
    <div class="modal-body">Are you sure want to delete this data?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="deleteDialog.isDeleting && loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="deleteDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Delete</button>
        </div>
    </div>
</ng-template>

<ng-template #formModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{formDialog.data?.id?'Edit':'Create'}} Vendor Price</h5>
    </div>
    <div class="modal-body">
        <div class="col-md-8">
            <div class="form-floating mb-4">
                <ng-select class="form-control" id="vendor-id" placeholder="-" [(ngModel)]="formDialog.data.vendor_id" loadingText="Loading..." required>
                    <ng-option *ngFor="let item of vendorList" [value]="item.id">{{item.name}}</ng-option>
                </ng-select>
                <label>Vendor Name</label>
            </div>
        </div>
        <div class="col-md-12">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center">#</th>
                        <th style="width:35%;">Vendor SoW</th>
                        <th>UoM</th>
                        <th class="text-end">Target Price</th>
                        <th>Vendor Price</th>
                        <th class="text-center"><i class="material-icons">close</i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sow of formDialog.data?.details;index as i;">
                        <td class="text-center">
                            {{i+1}}
                        </td>
                        <td>
                            <ng-select id="sow_{{i}}" class="form-control" placeholder="-" [(ngModel)]="sow.vendor_boq_id" (change)="formDialog.setItem(sow.vendor_boq_id,i)" loadingText="Loading..." required>
                                <ng-option *ngFor="let item of data.po_data.vendor_boqs" [value]="item.id">{{item.sow_name}}</ng-option>
                            </ng-select>
                        </td>
                        <td>
                            {{sow.vendor_boq?.uom || '-'}}
                        </td>
                        <td class="text-end">
                            {{(sow.vendor_boq?.price | number) || '-'}}
                        </td>
                        <td>
                            <input type="text" id="price_{{i}}" ngxNumberFormat="###,###,###,###,###" [(ngModel)]="sow.price" class="form-control my-2" required>
                        </td>
                        <td class="text-center">
                            <button type="button" class="btn btn-sm btn-danger my-2" (click)="formDialog.data?.details?.splice(i, 1)">
                                <i class="material-icons">close</i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="mb-4">
                <button type="button" class="btn btn-sm btn-secondary" (click)="formDialog.data?.details?.push({})">
                    <i class="material-icons">add</i>&nbsp;Add SoW
                </button>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="formDialog.isLoading">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-secondary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-primary" (click)="formDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Save</button>
        </div>
    </div>
</ng-template>
<div class="d-none">
    <div class="modal-lg modal-xl"></div>
</div>
