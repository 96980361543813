import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-po-additional-edit',
    templateUrl: './po-additional-edit.component.html',
    styleUrls: ['./po-additional-edit.component.scss']
})
export class PoAdditionalEditComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
        private el: ElementRef,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private dateParse: DatePickerParserService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'po-additionals';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = this.fb.group({});
    formChanged = false;
    contractList: any[] = [];
    poList: any[] = [];
    siteList: any[] = [];
    details: any[] = [];
    vendorList = new BehaviorSubject<any[]>([]);

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        if (!this.details.length) {
            this.toastr.error("PO Additional items can't be blank", 'Error');
            return;
        }
        const emptied = this.details.filter((e: any) => Number(e.qty) <= 0);
        if (emptied.length) {
            this.toastr.error("Please type Qty", 'Error');
            this.el.nativeElement.querySelectorAll('#item-qty')?.[0]?.focus();
            return;
        }
        form.value.requested_at = this.dateParse.toYMD(form.value.requested_at);
        form.value.qty = this.details?.[0]?.qty;
        form.value.price = this.details?.[0]?.price;
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    async getPO(contract_id: any): Promise<void> {
        this.formGroup.get('purchase_order_id')?.reset();
        this.formGroup.get('po_site_id')?.reset();
        const r = await this.http.Get('purchase-orders', { filter: { contract_id: contract_id, status: 1 }, vendor_boq: 1, forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getSite(po_id: any): Promise<void> {
        this.formGroup.get('po_site_id')?.reset();
        const r = await this.http.Get('sites/' + po_id, { forceView: true });
        this.siteList = r.response?.result?.data || [];
    }

    async getVendor(): Promise<void> {
        this.vendorList.next([]);
        const r = await this.http.Get('vendors', { filter: { status: 1, type: 'services' }, forceView: true });
        this.vendorList.next(r.response?.result?.data || []);
    }

    async setVendorBoq(): Promise<void> {
        const r = await this.http.Get('po-additional-price', { forceView: true });
        const data = r.response?.result?.data || {};
        if (!data.id) {
            this.toastr.error("PO Additional price is not found, please set it first", 'Set PO Additional Price');
            return;
        }
        this.details.push({
            sow_name: data.item_name,
            qty: 1,
            uom: data.uom,
            price: data.price,
            total: (1 * data.price)
        });
    }

    setTotal(item: any): void {
        item.total = Number(item.qty) * item.price;
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.getContract();
                this.getPO(r?.response?.result?.data?.contract_id);
                this.getSite(r?.response?.result?.data?.purchase_order_id);
                this.getVendor();
                this.vendorList.next([r?.response?.result?.data?.vendor]);
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key == 'details') {
                        this.details = r?.response?.result?.data[key]?.map((e: any) => {
                            return {
                                sow_name: e.item,
                                qty: e.qty,
                                uom: e.uom,
                                price: e.price,
                                total: (1 * e.price)
                            }
                        });
                    } else if (key == 'requested_at') {
                        this.formGroup.addControl(key, new FormControl(this.dateParse.toObject(r?.response?.result?.data[key])));
                    } else {
                        this.formGroup.addControl(key, new FormControl(r?.response?.result?.data[key]));
                    }
                });
            } else {
                this.back();
            }
        });

    }

}

