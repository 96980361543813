import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-report-supplier-price',
    templateUrl: './report-supplier-price.component.html',
    styleUrls: ['./report-supplier-price.component.scss']
})
export class ReportSupplierPriceComponent implements OnInit {

    constructor(
        private http: HttpService,
        private toastr: ToastrService,
        private auth: AuthService,
        public loading: LoadingService
    ) {
    }
    @ViewChild('reportForm') reportForm: NgForm | undefined;
    private apiPath = 'report-supplier-price';
    data: any = {};
    isDownload = false;
    submitLoading = false;

    setAll(fg: any): void {
        const wh: any[] = fg?.value;
        if (wh.indexOf(-1) > -1) {
            fg?.setValue(null);
        }
    }

    submitForm(page: number = 1): void {
        if (this.isDownload) {
            let url = environment.apiUrl + '/' + this.apiPath + '/download?token=' + this.auth.tokenData?.access_token;
            window.open(url, '_blank');
            return;
        }
        this.submitLoading = true;
        let fdata = new FormData();
        this.http.Post(this.apiPath + '?page=' + page, fdata).then((r: any) => {
            this.submitLoading = false;
            if (r.success) {
                this.data = r?.response?.result || {};
                if (!this.data?.data?.length) {
                    this.toastr.warning("No data found, please change filter");
                }
            }
        });
    }

    findPrice(material_id: any): any {
        return this.data?.supplier_list?.find((e: any) => e.material_id == material_id) || {};
    }

    ngOnInit(): void {
        //
    }

}
