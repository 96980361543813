<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 d-md-flex align-items-center mb-4">
                        <h4 class="card-title me-2 mb-md-0 mb-3">Report Vendor Progress</h4>
                    </div>
                    <div class="col-12">
                        <form #theForm="ngForm" novalidate>
                            <div [formGroup]="formGroup" class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <ng-select class="form-control" [items]="contractList" bindLabel="project_name" bindValue="id" [clearable]="false" placeholder="-" formControlName="contract_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('contract_id')?.invalid && (theForm.submitted || formGroup.get('contract_id')?.touched)" required>
                                            <ng-template class="d-flex" ng-option-tmp let-item="item">
                                                <div>{{item.project_name}}</div>
                                                <div class="small text-muted">Contract: {{item.contract_number}}</div>
                                                <div class="small text-muted">Customer: {{item.customer_name}}</div>
                                            </ng-template>
                                        </ng-select>
                                        <label>Project Name</label>
                                        <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('contract_id')?.invalid && (theForm.submitted || formGroup.get('contract_id')?.touched)">
                                            <small *ngIf="formGroup.get('contract_id')?.errors?.required">Please select project name</small>
                                            <small *ngIf="formGroup.get('contract_id')?.errors?.serverError">{{ formGroup.get("contract_id")?.errors?.serverError }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center border-top pt-3">
                                <div>
                                    <button type="button" (click)="isDownload=false;pageQuery.changeRoute({page:1,submit:true});" class="btn btn-secondary me-3" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <i class="material-icons">list</i> &nbsp;
                                        View
                                    </button>
                                    <button type="button" (click)="isDownload=true;submitForm();" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <i class="material-icons">file_download</i> &nbsp;
                                        Download
                                    </button>
                                </div>
                                <div class="text-end">
                                    <div class="spinner-border text-primary" role="status" *ngIf="submitLoading">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="data.data?.length" class="row">
    <div class="col-md-12 mt-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>PO Customer</th>
                                        <th>Customer Name</th>
                                        <th>Project Name</th>
                                        <th class="text-end">Project Progress</th>
                                        <th class="text-end">Document Progress</th>
                                        <th class="text-end">Total Progress</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data?.data; let i = index">
                                        <td class="text-center">
                                            {{ data.from + i }}
                                        </td>
                                        <td>
                                            <a routerLink="{{ item.id }}">
                                                {{ item.number }}
                                            </a>
                                        </td>
                                        <td>
                                            {{ item.contract?.customer_name }}
                                        </td>
                                        <td>
                                            {{ item.contract?.customer_name }}
                                        </td>
                                        <td class="text-end">
                                            {{ item.vendor_progress_value | number }}%
                                        </td>
                                        <td class="text-end">
                                            {{ item.vendor_document_value | number }}%
                                        </td>
                                        <td class="text-end">
                                            {{ (item.vendor_document_value+item.vendor_progress_value) | number }}%
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-nowrap align-items-center justify-content-center justify-content-between">
                    <div class="text-nowrap">
                        &nbsp;
                    </div>
                    <div class="text-nowrap">
                        <button (click)="pageQuery.changeRoute({page:1});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page===1 || !data.prev_page_url ? 'disabled' : null">
                            <span class="material-icons">first_page</span>
                        </button>
                        <button (click)="pageQuery.changeRoute({page:(data.prev_page_url).split('page=')[1]});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page===1 || !data.prev_page_url ? 'disabled' : null">
                            <span class="material-icons">navigate_before</span>
                        </button>
                        <span>
                            {{data.current_page || 1}}/{{data.last_page || '&infintie;'}}
                        </span>
                        <button (click)="pageQuery.changeRoute({page:(data.next_page_url).split('page=')[1]});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page===data.last_page ? 'disabled' : null">
                            <span class="material-icons">navigate_next</span>
                        </button>
                        <button (click)="pageQuery.changeRoute({page:data.last_page});" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page===data.last_page || !data.last_page ? 'disabled' : null">
                            <span class="material-icons">last_page</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
