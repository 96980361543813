<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-12 d-md-flex align-items-center">
                        <h4 class="card-title me-2 mb-md-0 mb-3">Notifications</h4>
                    </div>
                    <div class="col-md-6 col-sm-12 d-flex justify-content-end">
                        <div *ngIf="data.data?.length">
                            <button title="Refresh" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary ms-1" (click)="deleteDialog.show()">
                                Delete All
                            </button>
                            <button title="Search" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary ms-1" (click)="readDialog.show()">
                                Read All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-12">
                        <div class="text-center h4 p-3" *ngIf="!data?.data?.length">
                            <div *ngIf="loading.status">
                                <div class="spinner-grow" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!loading.status">No data found..</div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover" *ngIf="data?.data?.length">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Timestamp</th>
                                        <th class="text-center">
                                            <i class="material-icons">more_vert</i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data?.data; let i = index">
                                        <td class="text-center">
                                            {{ data.from + i }}
                                        </td>
                                        <td>
                                            {{ item.title }}
                                        </td>
                                        <td>
                                            {{ item.description }}
                                        </td>
                                        <td nowrap>
                                            {{ item.created_at | dateFormat : "DD MMM YYYY, HH:mm" }}
                                        </td>
                                        <td class="text-center" nowrap>
                                            <button class="btn btn-sm text-primary border-primary" (click)="gotoUrl(item)">Detail</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="data?.data?.length" class="d-flex justify-content-between row small">
                            <div class="col-md-2 small d-flex flex-nowrap row align-items-center">
                                &nbsp;
                            </div>
                            <div class="col-md-4 d-flex flex-nowrap align-items-center justify-content-center justify-content-md-end">
                                <div class="text-nowrap">Total: {{ data.total || 0 }}</div>
                                <div class="text-nowrap">
                                    <button (click)="pageQuery.changeRoute({ page: 1 })" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page === 1 || !data.prev_page_url ? 'disabled' : null">
                                        <span class="material-icons">first_page</span>
                                    </button>
                                    <button (click)="pageQuery.changeRoute({page: data.prev_page_url.split('page=')[1]})" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || data.current_page === 1 || !data.prev_page_url ? 'disabled' : null">
                                        <span class="material-icons">navigate_before</span>
                                    </button>
                                    <span> {{ data.current_page || 1 }}/{{ data.last_page || "&infintie;" }} </span>
                                    <button (click)="pageQuery.changeRoute({page: data.next_page_url.split('page=')[1]})" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page === data.last_page ? 'disabled' : null">
                                        <span class="material-icons">navigate_next</span>
                                    </button>
                                    <button (click)="pageQuery.changeRoute({page: data.last_page})" class="btn btn-sm border-0 m-1 p-1" [attr.disabled]="loading.status || !data.next_page_url || pageQuery.queryPage.page === data.last_page || !data.last_page ? 'disabled' : null">
                                        <span class="material-icons">last_page</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Delete</h5>
    </div>
    <div class="modal-body">Are you sure want to delete all notifications?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="deleteDialog.isDeleting && loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="deleteDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Delete</button>
        </div>
    </div>
</ng-template>

<ng-template #readModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Read All</h5>
    </div>
    <div class="modal-body">Are you sure want to mark all notificaiton as read?</div>
    <div class="modal-footer d-flex justify-content-between">
        <div>
            <div class="spinner-border text-primary" role="status" *ngIf="readDialog.isLoading && loading.status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="btn btn-primary" (click)="modal.close()" [attr.disabled]="loading.status ? 'disabled' : null">Cancel</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="readDialog.submit()" [attr.disabled]="loading.status ? 'disabled' : null">Read All</button>
        </div>
    </div>
</ng-template>
