<div *ngIf="dashboardLoading" class="d-flex w-100 h-100 justify-content-center align-items-center">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div *ngIf="!dashboardLoading" class="dashboard">
    <div *ngIf="!canAccess" class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body" (click)="ngOnInit()">
                    <h4>Hi {{userData.name}}, welcome to SRP App</h4>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="canAccess!=null">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs bg-light p-2 fw-bold rounded">
            <li *ngIf="canAccess?.customer" [ngbNavItem]="1">
                <a ngbNavLink>Customer</a>
                <ng-template ngbNavContent>
                    <div class="row mt-3">
                        <div class="col-12">
                            <app-dashboard-customer></app-dashboard-customer>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li *ngIf="canAccess?.vendor" [ngbNavItem]="2">
                <a ngbNavLink>Vendor</a>
                <ng-template ngbNavContent>
                    <div class="row mt-3">
                        <div class="col-12">
                            <app-dashboard-vendor></app-dashboard-vendor>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li *ngIf="canAccess?.customer_vendor" [ngbNavItem]="3">
                <a ngbNavLink>Customer vs Vendor</a>
                <ng-template ngbNavContent>
                    <div class="row mt-3">
                        <div class="col-12">
                            <app-dashboard-customer-vendor></app-dashboard-customer-vendor>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li *ngIf="canAccess?.finance" [ngbNavItem]="4">
                <a ngbNavLink>Finance</a>
                <ng-template ngbNavContent>
                    <div class="row mt-3">
                        <div class="col-12">
                            <app-dashboard-finance></app-dashboard-finance>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li *ngIf="canAccess?.finance" [ngbNavItem]="5">
                <a ngbNavLink>Timeline &amp; Budget</a>
                <ng-template ngbNavContent>
                    <div class="row mt-3">
                        <div class="col-12">
                            <app-dashboard-timeline-budget></app-dashboard-timeline-budget>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </ng-container>
</div>
