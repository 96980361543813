<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Kembali">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Approval Settings</h4>
                    </div>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="data.id" class="col-md-8 mt-3">
                        <div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.name" placeholder="..." class="form-control" disabled>
                                <label>Name</label>
                            </div>
                            <h4 class="card-title col-12 mb-4">Approval Rules</h4>
                            <div class="col-12 mb-4">
                                <em *ngIf="!data.rules.length">No Data</em>
                                <table *ngIf="data.rules.length" class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th style="width:45%;">Role</th>
                                            <th style="width:45%;">Level Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rule of data.rules;index as i;">
                                            <td>{{i+1}}</td>
                                            <td>
                                                {{rule?.role.name}}
                                            </td>
                                            <td style="text-transform: capitalize;">
                                                {{rule.level_name}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
