<div class="row mb-4">
    <div class="col-md-12 d-md-flex justify-content-between align-items-center">
        <div class="flex-fill">
            <h4 class="card-title me-2 mb-md-0 mb-3">Customer Dashboard</h4>
        </div>
        <div>
            <button title="Refresh" [attr.disabled]="progressLoading ? 'disabled' : null" class="btn btn-outline-primary btn-sm" (click)="getData()">
                <i class="material-icons">refresh</i>
            </button>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-3 mb-3">
        <div class="card bg-primary text-white">
            <div *ngIf="progressLoading" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!progressLoading" class="card-body text-center">
                <div class="mb-1 small">Count / Amount of active PO</div>
                <div class="fw-bold text-nowrap">{{(data.total?.count || 0) | number}} / IDR {{(data.total?.customer_boq || 0) | number}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-3">
        <div class="card bg-secondary text-white">
            <div *ngIf="progressLoading" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!progressLoading" class="card-body text-center">
                <div class="mb-1 small">Amount of material</div>
                <div class="fw-bold text-nowrap">IDR {{(data.total?.material || 0) | number}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-3">
        <div class="card bg-info text-white">
            <div *ngIf="progressLoading" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!progressLoading" class="card-body text-center">
                <div class="mb-1 small">Amount of vendor BoQ</div>
                <div class="fw-bold text-nowrap">IDR {{(data.total?.vendor_boq || 0) | number}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3 mb-3">
        <div class="card bg-danger text-white">
            <div *ngIf="progressLoading" class="card-body text-center">
                <div class="spinner-border text-white" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!progressLoading" class="card-body text-center">
                <div class="mb-1 small">Material+vendor BoQ</div>
                <div class="fw-bold text-nowrap">IDR {{((data.total?.material+data.total?.vendor_boq) || 0) | number}}</div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-md-12">
        <div class="card">
            <div *ngIf="progressLoading" class="card-body text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div [class.d-none]="progressLoading" class="card-body text-center">
                <h5>Total PO by project type</h5>
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="d-flex justify-content-center align-items-center">
                            <canvas #pieTotalPO style="height:350px"></canvas>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="d-flex justify-content-center align-items-center">
                            <canvas #barTotalPO style="height:350px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h5>Progress {{selectedPo?'per Site':'per PO'}}</h5>
                    </div>
                    <div class="col-md-4 d-md-flex justify-content-end">
                        <select class="form-select" [(ngModel)]="selectedPo" (ngModelChange)="getTopData(true)">
                            <option value="">- All PO -</option>
                            <option *ngFor="let p of poList" [value]="p.id">{{p.number}}({{p.contract?.customer_name}})</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="progressLoading" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div [class.d-none]="progressLoading" class="d-flex justify-content-center align-items-center">
                    <canvas #barProgress></canvas>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h5>Progress VS Document {{selectedCustomerPo?'per Site':'per PO'}}</h5>
                    </div>
                    <div class="col-md-4 d-md-flex justify-content-end">
                        <select class="form-select" [(ngModel)]="selectedCustomerPo" (ngModelChange)="getCustomerData(true)">
                            <option value="">- All PO -</option>
                            <option *ngFor="let p of poList" [value]="p.id">{{p.number}}({{p.contract?.customer_name}})</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="progressCustomerLoading" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div [class.d-none]="progressCustomerLoading" class="d-flex justify-content-center align-items-center mb-2">
                    <canvas #barCustomer></canvas>
                </div>
            </div>
        </div>
    </div>
</div>
