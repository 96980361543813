import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, Legend, Colors, Tooltip, BarElement, BarController, CategoryScale, LineController, LinearScale, PieController, ArcElement, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-dashboard-timeline-budget',
    templateUrl: './dashboard-timeline-budget.component.html',
    styleUrls: ['./dashboard-timeline-budget.component.scss']
})
export class DashboardTimelineBudgetComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpService,
        public loading: LoadingService
    ) {
        Chart.register(
            ChartDataLabels,
            Colors,
            Legend,
            Tooltip,
            BarElement,
            BarController,
            CategoryScale,
            LineController,
            LinearScale,
            PieController,
            ArcElement
        );
    }

    @ViewChild('barTimeline') barTimeline!: ElementRef;
    @ViewChild('barBudget') barBudget!: ElementRef;
    private apiPath = 'dashboard';
    barTimelineChart!: any;
    barBudgetChart!: any;
    data: any = {};
    chartOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                min: 0,
                max: 100,
            }
        },
        plugins: {
            datalabels: {
                formatter: (value: number) => {
                    return formatNumber(value, 'en');
                }
            },
        }
    };

    async getData(): Promise<void> {

        const r = await this.http.Get(this.apiPath + '/timeline-budget', { forceView: true });
        this.data = r.response?.result || {};

        this.barTimelineChart?.destroy();
        let barTimelineData: ChartData<'bar'> = { labels: this.data?.timeline?.labels, datasets: this.data?.timeline?.datasets };
        this.barTimelineChart = new Chart(this.barTimeline.nativeElement, {
            type: 'bar',
            data: barTimelineData,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return formatNumber(value, 'en') + '%';
                        }
                    },
                }
            }
        });

        this.barBudgetChart?.destroy();
        let barBudgetData: ChartData<'bar'> = { labels: this.data?.budget?.labels, datasets: this.data?.budget?.datasets };
        this.barBudgetChart = new Chart(this.barBudget.nativeElement, {
            type: 'bar',
            data: barBudgetData,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return formatNumber(value, 'en')
                        }
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return 'IDR ' + formatNumber(context.parsed.y, 'en');
                            }
                        }
                    }
                }
            }
        });

    }

    ngOnInit(): void {
        //
    }

    ngAfterViewInit(): void {
        this.getData();
    }
}
