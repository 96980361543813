<div class="row mb-4">
    <div class="col-md-12 d-md-flex justify-content-between align-items-center">
        <div class="flex-fill">
            <h4 class="card-title me-2 mb-md-0 mb-3">Timeline &amp; Budget</h4>
        </div>
        <div>
            <button title="Refresh" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-outline-primary btn-sm" (click)="getData()">
                <i class="material-icons">refresh</i>
            </button>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h5>Timeline vs Progress</h5>
                    </div>
                </div>
                <div *ngIf="loading.status" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div [class.d-none]="loading.status" class="d-flex justify-content-center align-items-center mb-2">
                    <canvas #barTimeline></canvas>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h5>Budget vs Actual</h5>
                    </div>
                </div>
                <div *ngIf="loading.status" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div [class.d-none]="loading.status" class="d-flex justify-content-center align-items-center mb-2">
                    <canvas #barBudget></canvas>
                </div>
            </div>
        </div>
    </div>
</div>
