import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-approval-setting-edit',
    templateUrl: './approval-setting-edit.component.html',
    styleUrls: ['./approval-setting-edit.component.scss']
})
export class ApprovalSettingEditComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private el: ElementRef,
        private fb: FormBuilder,
        private toastr: ToastrService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'approvals';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    formGroup = this.fb.group({});
    formChanged = false;
    roleList: any[] = [];

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }

        let fdata = new FormData();
        if (!form.value.rules.length) {
            this.toastr.error('Please add Rule(s)', 'Approval Rules');
            return;
        }
        form.value.rules = form.value.rules.map((e: any, i: number) => {
            e.level_order = i
            return e;
        });
        form.value.status = Number(form.value.status);
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.PostFile(this.apiPath + '/update', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    async getRole(): Promise<void> {
        let r = await this.http.Get('roles', { forceView: true });
        this.roleList = r.response?.result?.data || [];
    }

    get rules(): FormArray | null {
        return this.formGroup.get('rules') as FormArray | null;
    }

    addRules(): void {
        const rules = this.rules;
        rules?.push(new FormGroup({
            role_id: new FormControl(null),
            level_name: new FormControl(null),
            level_order: new FormControl(null)
        }));
    }

    removeRules(i: number): void {
        const rules = this.rules;
        rules?.removeAt(i);
        if (!rules?.length) {
            this.addRules();
        }
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.getRole();
                Object.keys(r?.response?.result?.data).forEach((key) => {
                    if (key == 'rules') {
                        let rules: FormGroup[] = [];
                        let rl = r.response.result.data.rules;
                        rl = !rl.length ? [{}] : rl;
                        rl.forEach((e: any) => {
                            rules.push(new FormGroup({
                                id: new FormControl(e.id),
                                role_id: new FormControl(e.role_id),
                                level_name: new FormControl(e.level_name),
                                level_order: new FormControl(e.level_order)
                            }));
                        });
                        this.formGroup.addControl(key, new FormArray(rules));
                    } else {
                        this.formGroup.addControl(key, new FormControl(r?.response?.result?.data[key]));
                    }
                });
            } else {
                this.back();
            }
        });
    }

}
