import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-purchase-order-detail',
    templateUrl: './purchase-order-detail.component.html',
    styleUrls: ['./purchase-order-detail.component.scss']
})
export class PurchaseOrderDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private auth: AuthService,
        private location: Location,
        public loading: LoadingService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'purchase-orders';
    id: any;
    data: any = {};

    back(): void {
        this.location.back();
    }

    numToAplha(n: number): string {
        return String.fromCharCode(97 + n).toUpperCase();
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data;
            } else {
                this.back();
            }
        });

    }

    downloadFile(file: string): void {
        const url = environment.apiUrl + '/download/' + file + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

}


