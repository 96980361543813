<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Edit Material</h4>
                    <div class="text-center" *ngIf="!formGroup.get('id')?.value">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <form *ngIf="formGroup.get('id')?.value" #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-12">
                        <div class="row" [formGroup]="formGroup">
                            <div class="col-md-8">
                                <div class="form-floating mb-4">
                                    <input type="text" formControlName="name" [class.is-invalid]="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)" placeholder="..." class="form-control" required />
                                    <label>Name</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)">
                                        <small *ngIf="formGroup.get('name')?.errors?.required">Please type name</small>
                                        <small *ngIf="formGroup.get('name')?.errors?.serverError">{{ formGroup.get("name")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" placeholder="-" formControlName="uom" loadingText="Loading..." [class.is-invalid]="formGroup.get('uom')?.invalid && (theForm.submitted || formGroup.get('uom')?.touched)" required>
                                        <ng-option *ngFor="let item of (uomList | async)" [value]="item">{{item}}</ng-option>
                                    </ng-select>
                                    <label>UoM</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('uom')?.invalid && (theForm.submitted || formGroup.get('uom')?.touched)">
                                        <small *ngIf="formGroup.get('uom')?.errors?.required">Please type UoM</small>
                                        <small *ngIf="formGroup.get('uom')?.errors?.serverError">{{ formGroup.get("uom")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-8 mb-4">
                                        <div class="w-50">
                                            <div style="height: 200px" [ngClass]="{'bg-secondary bg-opacity-10': !image.view}" [ngStyle]="image.view && {background: 'url(' + image.view + ') center no-repeat', 'background-size': 'contain'}" class="border rounded d-flex justify-content-center align-items-center position-relative flex-wrap">
                                                <div *ngIf="!image.view">
                                                    {{ "Material image" }}
                                                </div>
                                            </div>
                                            <div>
                                                <div class="small text-muted mb-2">File type: jpg, png; Max size: 2mb<br />Recomended dimension: 480x512 pixel</div>
                                                <div class="text-center">
                                                    <button type="button" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-secondary btn-sm" (click)="fileInput.click()"><i class="material-icons">file_upload</i>&nbsp; Upload</button>
                                                    <input type="file" class="d-none" #fileInput accept="image/jpg,image/jpeg,image/png" (change)="fileHandler.handle($event, image, 'image/jpg,image/jpeg,image/png', 2)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check form-switch mb-4">
                                    <input class="form-check-input" type="checkbox" formControlName="status" id="flexSwitchCheckDefault" />
                                    <label class="form-check-label" for="flexSwitchCheckDefault">Status : <strong>{{ formGroup.get("status")?.value ? "Active" : "Inactive" }}</strong></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="d-flex justify-content-between align-items-center border-top pt-1">
                                    <div>&nbsp;</div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                        &nbsp;
                                        <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
