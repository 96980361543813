<div class="row mb-4">
    <div class="col-sm-12 col-md-8 col-lg-6 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Add Menu</h4>
                    <form #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate>
                        <div [formGroup]="formGroup">
                            <div class="form-floating mb-4">
                                <ng-select class="form-control" placeholder="No Parent" formControlName="parent_id" loadingText="Loading...">
                                    <ng-option *ngFor="let item of parentList" [value]="item.id">
                                        <div class="d-flex align-items-center">
                                            <i class="material-icons me-2">{{item.icon}}</i>
                                            {{item.name}}
                                        </div>
                                    </ng-option>
                                </ng-select>
                                <label>Parent</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="name" [class.is-invalid]="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)" placeholder="..." class="form-control" required>
                                <label>Name</label>
                                <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('name')?.invalid && (theForm.submitted || formGroup.get('name')?.touched)">
                                    <small *ngIf="formGroup.get('name')?.errors?.required">Please type name</small>
                                    <small *ngIf="formGroup.get('name')?.errors?.serverError">{{formGroup.get('name')?.errors?.serverError}}</small>
                                </div>
                            </div>
                            <div class="input-group mb-4 w-100">
                                <div class="form-floating col-10">
                                    <input type="text" formControlName="icon" [class.is-invalid]="formGroup.get('icon')?.invalid && (theForm.submitted || formGroup.get('icon')?.touched)" placeholder="..." class="form-control">
                                    <label>Icon</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('icon')?.invalid && (theForm.submitted || formGroup.get('icon')?.touched)">
                                        <small *ngIf="formGroup.get('icon')?.errors?.required">Please type icon name</small>
                                        <small *ngIf="formGroup.get('icon')?.errors?.serverError">{{formGroup.get('icon')?.errors?.serverError}}</small>
                                    </div>
                                </div>
                                <div class="input-group-text col-2 d-flex justify-content-center align-items-center">
                                    <div>
                                        <i class="material-icons">{{formGroup.get('icon')?.value}}</i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="link" placeholder="..." class="form-control">
                                <label>Link</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="number" required formControlName="position" placeholder="..." class="form-control">
                                <label>Position</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" formControlName="action" placeholder="..." class="form-control">
                                <label>Actions</label>
                                <div class="form-text">
                                    Comma separated i.e:
                                    <pre class="d-inline fw-bold" (click)="formGroup.get('action')?.setValue('create,update,delete')">create,update,delete</pre>
                                </div>
                            </div>
                            <div class="form-check form-switch mb-4">
                                <input class="form-check-input" type="checkbox" formControlName="status" id="flexSwitchCheckDefault">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Status : <strong>{{formGroup.get('status')?.value ? 'Active' : 'Inactive'}}</strong></label>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-top pt-1">
                            <div>
                                <div class="form-check">
                                    <input class="form-check-input" [(ngModel)]="stayPage" [ngModelOptions]="{standalone: true}" type="checkbox" id="stayPage" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <label class="form-check-label" for="stayPage">
                                        Stay on this page
                                    </label>
                                </div>
                            </div>
                            <div class="text-end">
                                <button type="button" class="btn btn-secondary" (click)="back();" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <i class="material-icons">close</i> &nbsp;
                                    Cancel
                                </button>
                                &nbsp;
                                <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <i class="material-icons">save</i> &nbsp;
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
