import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-vendor-billing-document-detail',
    templateUrl: './vendor-billing-document-detail.component.html',
    styleUrls: ['./vendor-billing-document-detail.component.scss']
})
export class VendorBillingDocumentDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private auth: AuthService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
            this.route.queryParams.subscribe(q => {
                const p = new URLSearchParams(q).toString();
                if (p) {
                    this.id += '?' + p;
                }
            });
        });
    }
    @ViewChild('approveModal') approveModal: any;
    @ViewChild('rejectModal') rejectModal: any;
    private apiPath = 'vendor-billing-docs';
    id: any;
    data: any = {}

    approveDialog: any = {
        modal: null,
        show: () => {
            this.approveDialog.modal = this.modalService.open(this.approveModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            fdata.append('id', this.id);
            this.http.Post(this.apiPath + '/approve', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data approved successfully", 'Success');
                    this.ngOnInit();
                    this.approveDialog.modal.close();
                }
            })
        }
    }

    rejectDialog: any = {
        modal: null,
        remarks: null,
        show: () => {
            this.rejectDialog.modal = this.modalService.open(this.rejectModal, { keyboard: false, backdrop: 'static', centered: true });
        },
        submit: () => {
            let fdata = new FormData();
            fdata.append('id', this.id);
            fdata.append('remarks', this.rejectDialog.remarks);
            this.http.Post(this.apiPath + '/reject', fdata).then((r: any) => {
                if (r.success) {
                    this.toastr.success("Data rejected successfully", 'Success');
                    this.ngOnInit();
                    this.rejectDialog.modal.close();
                }
            })
        }
    }

    rejectRemarks(): any {
        const appr = (this.data.approvals.find((e: any) => e.status == -1)) || {};
        return appr.remarks || null;
    }

    myApproval(): any {
        const e: any[] = (this.data.approvals || []).filter((e: any) => e.is_me && e.show_notification) || [];
        return e[e.length - 1] || {};
    }

    downloadFile(file: string): void {
        const url = environment.apiUrl + '/download/' + file + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

    downloadCrt(): void {
        const url = environment.apiUrl + '/vendor-document/certificate/' + this.data.documents?.[0]?.vendor_document_id + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, { forceView: true }).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data
            } else {
                this.toastr.error('Data not found', 'Error');
                this.back();
            }
        });

    }
}
