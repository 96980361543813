import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PageQueryService } from 'src/app/services/page-query.service';

@Component({
    selector: 'app-vendor-billing-document-view',
    templateUrl: './vendor-billing-document-view.component.html',
    styleUrls: ['./vendor-billing-document-view.component.scss']
})
export class VendorBillingDocumentViewComponent implements OnInit, OnDestroy {
    constructor(
        public pageQuery: PageQueryService,
        public loading: LoadingService,
        private http: HttpService
    ) {
        this.loading.start();
    }

    private apiPath = 'vendor-billing-docs';
    data: any = {};
    permission: any = {};
    poList: any = [];
    siteList: any = [];

    async getPo(): Promise<void> {
        const r = await this.http.Get('purchase-orders', { forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getSite(poId: string): Promise<void> {
        if (!poId) return;
        const r = await this.http.Get('sites/' + poId, { forceView: true });
        this.siteList = r.response?.result?.data || [];
    }

    getData(): void {
        const query: any = this.pageQuery.query.getValue() || {};
        query.limit = query?.limit || 10;
        query.page = query?.page || 1;
        this.http.Get(this.apiPath, query).then((r: any) => {
            if (r.success) {
                this.data = r?.response?.result || {};
                this.permission = r?.permission || {};
            }
        });
    }

    ngOnInit(): void {
        const query: any = this.pageQuery.queryPage || {};
        if (query.po) {
            this.getSite(query.po);
        } else {
            this.pageQuery.queryPage.po = '';
            this.pageQuery.queryPage.site = '';
        }
        this.getPo();
        this.pageQuery.init(() => {
            this.getData();
        });
    }

    ngOnDestroy(): void {
        this.pageQuery.destroy();
    }

}
