import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PageQueryService } from 'src/app/services/page-query.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-report-vendor-progress',
    templateUrl: './report-vendor-progress.component.html',
    styleUrls: ['./report-vendor-progress.component.scss']
})
export class ReportVendorProgressComponent implements OnInit, OnDestroy {

    constructor(
        public pageQuery: PageQueryService,
        private http: HttpService,
        private toastr: ToastrService,
        private el: ElementRef,
        private auth: AuthService,
        public loading: LoadingService,
    ) {
    }
    @ViewChild('reportForm') reportForm: NgForm | undefined;
    private apiPath = 'report-vendor-progress';
    formGroup = new FormGroup({
        contract_id: new FormControl()
    });
    data: any = {};
    poList: any[] = [];
    contractList: any[] = [];
    isDownload = false;
    submitLoading = false;

    submitForm(): void {
        if (!this.formGroup.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')[0]?.focus();
            return;
        }
        if (this.isDownload) {
            let val: any = {};
            const form: any = this.formGroup.value;
            Object.keys(form).forEach((key: any) => {
                if (form[key] != null) {
                    val[key] = form[key];
                }
            });
            let params = new URLSearchParams(val).toString();
            let url = environment.apiUrl + '/' + this.apiPath + '/download-summary?token=' + this.auth.tokenData?.access_token + '&' + params;
            window.open(url, '_blank');
            return;
        }
        if (!this.formGroup.value?.contract_id) {
            return;
        }
        this.submitLoading = true;
        this.pageQuery.changeRoute({ contract_id: this.formGroup.value?.contract_id });
        let fdata = new FormData();
        fdata.append('contract_id', this.formGroup.value?.contract_id);
        this.formGroup.disable();
        const query: any = this.pageQuery.query.getValue() || {};
        this.http.Post(this.apiPath + '/summary?page=' + (query.page || 1), fdata).then((r: any) => {
            this.submitLoading = false;
            this.formGroup.enable();
            if (r.success) {
                this.data = r?.response?.result || {};
                if (!this.data?.data?.length) {
                    this.toastr.warning("No data found, please change filter");
                }
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key == 'is_download') return;
                        this.formGroup.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                        this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')[0]?.focus();
                    });
                }
            }
        });
    }

    async getContract(): Promise<void> {
        const r = await this.http.Get('contracts', { filter: { status: 1 }, forceView: true });
        this.contractList = r.response?.result?.data || [];
    }

    ngOnInit(): void {
        this.getContract();
        if (this.pageQuery.queryPage.contract_id) {
            this.formGroup.get('contract_id')?.setValue(this.pageQuery.queryPage.contract_id);
        }
        this.pageQuery.init(() => {
            const query: any = this.pageQuery.query.getValue() || {};
            if (query.submit) {
                this.submitForm();
            }
        });
    }

    ngOnDestroy(): void {
        this.pageQuery.destroy();
    }

}
