import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-vendor-billing-document-paid',
    templateUrl: './vendor-billing-document-paid.component.html',
    styleUrls: ['./vendor-billing-document-paid.component.scss']
})
export class VendorBillingDocumentPaidComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private el: ElementRef,
        private http: HttpService,
        private location: Location,
        private dateParse: DatePickerParserService,
        public loading: LoadingService,
        private toastr: ToastrService,
        private auth: AuthService
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
            this.route.queryParams.subscribe(q => {
                const p = new URLSearchParams(q).toString();
                if (p) {
                    this.id += '?' + p;
                }
            });
        });
    }
    private apiPath = 'vendor-billing-docs';
    id: any;
    data: any = {};
    formGroup = new FormGroup({
        paid_date: new FormControl()
    });

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.scrollIntoView({ behavior: "smooth", block: "center" });
            return;
        }
        form.value.id = this.id;
        form.value.paid_date = this.dateParse.toYMD(form.value.paid_date);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        form.disable();
        this.http.Post(this.apiPath + '/set-status', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    downloadFile(file: string): void {
        const url = environment.apiUrl + '/download/' + file + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

    downloadCrt(): void {
        const url = environment.apiUrl + '/vendor-document/certificate/' + this.data.documents?.[0]?.vendor_document_id + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, { forceView: true }).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data
            } else {
                this.toastr.error('Data not found', 'Error');
                this.back();
            }
        });

    }
}
