import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-report-vendor-progress-detail',
    templateUrl: './report-vendor-progress-detail.component.html',
    styleUrls: ['./report-vendor-progress-detail.component.scss']
})
export class ReportVendorProgressDetailComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
        private auth: AuthService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'report-vendor-progress';
    id: any;
    @ViewChild('theForm') theForm: NgForm | undefined;
    data: any = {};

    back(): void {
        this.location.back();
    }

    download(): void {
        let url = environment.apiUrl + '/' + this.apiPath + '/download-detail?token=' + this.auth.tokenData?.access_token + '&id=' + this.id;
        window.open(url, '_blank');
    }

    ngOnInit(): void {
        let fdata = new FormData();
        fdata.append('id', this.id);
        this.http.Post(this.apiPath + '/detail', fdata).then((r: any) => {
            if (r.success) {
                this.data = r?.response?.result || {};
            }
        });

    }

}


