<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="d-flex flex-nowrap align-items-center">
                        <button class="btn btn-secondary btn-sm me-3" (click)="back()" title="Back">
                            <i class="material-icons">arrow_back</i>
                        </button>
                        <h4 class="card-title col-12 lh-0 m-0">Detail Contract</h4>
                    </div>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div *ngIf="data.id" class="col-md-8 mt-3">
                        <div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.project_type?.code+' - '+data.project_type?.name" placeholder="..." class="form-control" readonly>
                                <label>Project Type</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.project_category?.code+' - '+data.project_category?.name" placeholder="..." class="form-control" readonly>
                                <label>Project Category</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input [value]="data.project_code" placeholder="..." class="form-control" readonly>
                                <label>Project Code</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.project_name" placeholder="..." class="form-control" readonly>
                                <label>Project Name</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.customer_name" placeholder="..." class="form-control" readonly>
                                <label>Customer Name</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.contract_number" placeholder="..." class="form-control" readonly>
                                <label>Contract Number</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.contract_date | dateFormat:'DD MMM YYYY'" placeholder="..." class="form-control" readonly>
                                <label>Contract Date</label>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" [value]="(data.start_date | dateFormat:'DD MMM YYYY') || '-'" placeholder="..." class="form-control" readonly>
                                        <label>Start Date</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <input type="text" [value]="(data.end_date | dateFormat:'DD MMM YYYY') || '-'" placeholder="..." class="form-control" readonly>
                                        <label>End Date</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.status==1?'Active':'Inactive'" placeholder="..." class="form-control" readonly>
                                <label>Status</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.created_at | dateFormat" placeholder="..." class="form-control" readonly>
                                <label>Created at</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.creator?.name || '-'" placeholder="..." class="form-control" readonly>
                                <label>Created By</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="(data.last_updated_at | dateFormat) || '-'" placeholder="..." class="form-control" readonly>
                                <label>Last Updated at</label>
                            </div>
                            <div class="form-floating mb-4">
                                <input type="text" [value]="data.updater?.name ||'-'" placeholder="..." class="form-control" readonly>
                                <label>Last Updated By</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="data.documents?.length" class="col-md-4">
                        <table class="table">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Document Name</td>
                                    <td class="text-center">Download</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let d of data.documents;index as i;">
                                    <td>
                                        {{i+1}}
                                    </td>
                                    <td>
                                        {{d.name}}
                                    </td>
                                    <td class="text-center">
                                        <button class="btn btn-sm" (click)="downloadFile(d.file)">
                                            <i class="material-icons">file_download</i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
