import { formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart, Legend, Colors, Tooltip, BarElement, BarController, CategoryScale, LineController, LinearScale, PieController, ArcElement, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
    selector: 'app-dashboard-customer',
    templateUrl: './dashboard-customer.component.html',
    styleUrls: ['./dashboard-customer.component.scss']
})
export class DashboardCustomerComponent implements OnInit, AfterViewInit {
    constructor(
        private http: HttpService,
        public loading: LoadingService
    ) {
        Chart.register(
            ChartDataLabels,
            Colors,
            Legend,
            Tooltip,
            BarElement,
            BarController,
            CategoryScale,
            LineController,
            LinearScale,
            PieController,
            ArcElement
        );
    }

    @ViewChild('pieTotalPO') pieTotalPO!: ElementRef;
    @ViewChild('barTotalPO') barTotalPO!: ElementRef;
    @ViewChild('barProgress') barProgress!: ElementRef;
    @ViewChild('barCustomer') barCustomer!: ElementRef;
    private apiPath = 'dashboard';
    pieTotalPOChart!: any;
    barTotalPOChart!: any;
    barProgressChart!: any;
    barCustomerChart!: any;
    data: any = {};
    poList: any = [];
    selectedPo: string = '';
    selectedCustomerPo: string = '';
    progressCustomerLoading = true;
    progressLoading = true;

    async getPo(): Promise<void> {
        const r = await this.http.Get('purchase-orders', { forceView: true });
        this.poList = r.response?.result?.data || [];
    }

    async getTopData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedPo = '';
        }
        this.progressLoading = true;
        const r = await this.http.Get(this.apiPath + '/customer', { po_id: (this.selectedPo || null), forceView: true }, progressOnly);
        this.data = r.response?.result || {};
        if (!progressOnly) {
            this.pieTotalPOChart?.destroy();
            let pieTotalPOData: ChartData<'pie'> = { labels: [], datasets: [{ data: [], label: 'Total' }] };
            this.data.total_po?.pie?.forEach((e: any) => {
                pieTotalPOData?.labels?.push(e.name);
                pieTotalPOData.datasets[0].data.push(e.total);
            });
            this.pieTotalPOChart = new Chart(this.pieTotalPO.nativeElement, {
                type: 'pie',
                data: pieTotalPOData,
                options: {
                    responsive: false,
                    maintainAspectRatio: false,
                    plugins: {
                        datalabels: {
                            formatter: (value: number, ctx: any) => {
                                const datapoints = ctx.chart.data.datasets[0].data
                                const total = datapoints.reduce((t: number, d: number) => Number(t) + Number(d), 0)
                                if (!total) return 0;
                                const percentage = value / Number(total) * 100;
                                return percentage.toFixed(2) + "%";
                            }
                        },
                    }
                }
            });

            this.barTotalPOChart?.destroy();
            let barTotalPOData: ChartData<'bar'> = { labels: [], datasets: [{ data: [], label: 'Amount' }] };
            this.data.total_po?.bar?.forEach((e: any) => {
                barTotalPOData?.labels?.push(e.name);
                e.data.forEach((d: any) => {
                    barTotalPOData.datasets[0].data.push(d.total);
                })
            });

            this.barTotalPOChart = new Chart(this.barTotalPO.nativeElement, {
                type: 'bar',
                data: barTotalPOData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        datalabels: {
                            formatter: (value: number) => {
                                return formatNumber(value, 'en');
                            }
                        },
                    }
                }
            });
        }
        this.barProgressChart?.destroy();
        let barProgressData: ChartData<'bar'> = { labels: [], datasets: [] };
        this.data.progress?.forEach((e: any) => {
            let data: any = [];
            e.data.forEach((d: any) => {
                barProgressData?.labels?.push(d.name);
                data.push(d.total);
            })
            barProgressData.datasets.push({ label: e.name, data: data });
        });
        this.barProgressChart = new Chart(this.barProgress.nativeElement, {
            type: 'bar',
            data: barProgressData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return value + '%';
                        }
                    },
                }
            }
        });
        this.progressLoading = false;
    }

    async getCustomerData(progressOnly: boolean = false): Promise<void> {
        if (!progressOnly) {
            this.selectedCustomerPo = '';
        }
        this.progressCustomerLoading = true;
        const r = await this.http.Get(this.apiPath + '/customer-doc', { po_id: (this.selectedCustomerPo || null), forceView: true }, progressOnly);
        const data = r.response?.result || {};

        this.barCustomerChart?.destroy();
        let barProgressCustomerData: ChartData<'bar'> = { labels: data?.labels, datasets: data?.datasets };
        this.barCustomerChart = new Chart(this.barCustomer.nativeElement, {
            type: 'bar',
            data: barProgressCustomerData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                    }
                },
                plugins: {
                    datalabels: {
                        formatter: (value: number) => {
                            return value + '%';
                        }
                    },
                }
            }
        });

        this.progressCustomerLoading = false;
    }

    async getData(): Promise<void> {
        this.progressLoading = true;
        this.progressCustomerLoading = true;
        await this.getTopData();
        this.getCustomerData();
    }

    ngOnInit(): void {
        this.getPo();
    }

    ngAfterViewInit(): void {
        this.getData();
    }
}
