<div class="row mb-4">
    <div class="col-md-12 d-md-flex justify-content-between align-items-center">
        <div class="flex-fill">
            <h4 class="card-title me-2 mb-md-0 mb-3">Customer vs Vendor Progress</h4>
        </div>
        <div>
            <button title="Refresh" [attr.disabled]="loading.status ? 'disabled' : null" class="btn btn-outline-primary btn-sm" (click)="getData()">
                <i class="material-icons">refresh</i>
            </button>
        </div>
    </div>
</div>

<div class="row mb-4">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        <h5>Progress {{selectedPo?'per Site':'per PO'}}</h5>
                    </div>
                    <div class="col-md-4 d-md-flex justify-content-end">
                        <select class="form-select" [(ngModel)]="selectedPo" (ngModelChange)="getData(true)">
                            <option value="">- All PO -</option>
                            <option *ngFor="let p of poList" [value]="p.id">{{p.number}}({{p.contract?.customer_name}})</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="progressLoading" class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div [class.d-none]="progressLoading" class="d-flex justify-content-center align-items-center">
                    <canvas #barProgress></canvas>
                </div>
            </div>
        </div>
    </div>
</div>
