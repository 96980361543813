import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UomService } from 'src/app/services/uom.service';

@Component({
    selector: 'app-material-add',
    templateUrl: './material-add.component.html',
    styleUrls: ['./material-add.component.scss']
})
export class MaterialAddComponent implements OnInit {

    constructor(
        private http: HttpService,
        private el: ElementRef,
        private location: Location,
        public fileHandler: FileHandlerService,
        public loading: LoadingService,
        private uom: UomService,
        private toastr: ToastrService) { }
    @ViewChild('theForm') theForm: NgForm | undefined;
    private apiPath = 'materials';
    parentList: any = [];
    stayPage: boolean = false;
    formGroup = new FormGroup({
        code: new FormControl(null),
        name: new FormControl(null),
        uom: new FormControl(null),
        status: new FormControl(1),
    });
    formChanged = false;
    image: any = {};
    uomList = this.uom.getUoMList();

    submitForm(form: any): void {
        if (!form.valid) {
            this.el.nativeElement.querySelectorAll('[formcontrolname].ng-invalid')?.[0]?.focus();
            return;
        }
        form.value.status = Number(form.value.status);
        let fdata = new FormData();
        fdata.append('data', JSON.stringify(form.value));
        if (this.image?.file) {
            fdata.append('image', this.image.file);
        }
        form.disable();
        this.http.Post(this.apiPath + '/create', fdata).then((r: any) => {
            form.enable();
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            } else {
                if (r.response && r.response.wrong) {
                    Object.keys(r.response.wrong).forEach((key) => {
                        if (key != 'id') {
                            form.get(key)?.setErrors({ serverError: r.response.wrong[key][0] });
                            this.el.nativeElement.querySelectorAll('[formcontrolname="' + key + '"]')?.[0]?.focus();
                        }
                    });
                }
            }
        });
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        const oldValue = this.formGroup.value;
        this.formGroup.valueChanges.subscribe((e: any) => {
            this.formChanged = (oldValue != e);
        });
    }

}
