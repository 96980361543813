import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, /*CanDeactivateFn,*/ Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
// import { FormBaseComponent } from '../components/form-base/form-base.component';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
class AfterLoginService {

    constructor(
        private router: Router,
        private auth: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth.isLoggedIn()) {
            this.router.navigate(['login'], { queryParams: { return: state.url } });
        }
        return this.auth.isLoggedIn();
    }
}
export const AfterLoginGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(AfterLoginService).canActivate(next, state);
}

@Injectable({
    providedIn: 'root'
})
class BeforLoginService {

    constructor(
        private router: Router,
        private auth: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.auth.isLoggedIn()) {
            this.router.navigate(['dashboard']);
        }
        return !this.auth.isLoggedIn();
    }
}
export const BeforeLoginGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(BeforLoginService).canActivate(next, state);
}

export interface CanExit {
    canExit: () => Promise<boolean> | boolean;
}

export const CanDeactivateGuard: CanDeactivateFn<CanExit> = (component: CanExit, next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean => {
    return component.canExit();
}

// @Injectable({
//     providedIn: 'root'
// })
// export class CanExitConfirm {
//     constructor(private modalService: NgbModal) { }
//     private modalComponent!: FormBaseComponent;

//     public async exitConfirm(formChanges: boolean): Promise<boolean> {
//         if (!formChanges) {
//             return true;
//         }
//         this.modalComponent = new FormBaseComponent(this.modalService);
//         return this.modalComponent.canExit();
//     }
// }
