<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 d-md-flex align-items-center mb-4">
                        <h4 class="card-title me-2 mb-md-0 mb-3">Report Vendor Price</h4>
                    </div>
                    <div class="col-12">
                        <form #theForm="ngForm" novalidate>
                            <div [formGroup]="formGroup" class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <ng-select class="form-control" [items]="contractList" bindLabel="project_name" bindValue="id" [clearable]="false" (ngModelChange)="getPO()" placeholder="-" formControlName="contract_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('contract_id')?.invalid && (theForm.submitted || formGroup.get('contract_id')?.touched)" required>
                                            <ng-template class="d-flex" ng-option-tmp let-item="item">
                                                <div>{{item.project_name}}</div>
                                                <div class="small text-muted">Contract: {{item.contract_number}}</div>
                                            </ng-template>
                                        </ng-select>
                                        <label>Project Name</label>
                                        <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('contract_id')?.invalid && (theForm.submitted || formGroup.get('contract_id')?.touched)">
                                            <small *ngIf="formGroup.get('contract_id')?.errors?.required">Please select project name</small>
                                            <small *ngIf="formGroup.get('contract_id')?.errors?.serverError">{{ formGroup.get("contract_id")?.errors?.serverError }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-4">
                                        <ng-select class="form-control" [clearable]="false" placeholder="-" formControlName="purchase_order_id" loadingText="Loading..." [class.is-invalid]="formGroup.get('purchase_order_id')?.invalid && (theForm.submitted || formGroup.get('purchase_order_id')?.touched)" required>
                                            <ng-option *ngFor="let item of poList" [value]="item.id">{{item.number}}</ng-option>
                                        </ng-select>
                                        <label>PO Customer</label>
                                        <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('purchase_order_id')?.invalid && (theForm.submitted || formGroup.get('purchase_order_id')?.touched)">
                                            <small *ngIf="formGroup.get('purchase_order_id')?.errors?.required">Please select PO Customer</small>
                                            <small *ngIf="formGroup.get('purchase_order_id')?.errors?.serverError">{{ formGroup.get("purchase_order_id")?.errors?.serverError }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center border-top pt-3">
                                <div>
                                    <button type="button" (click)="isDownload=false;submitForm(formGroup);" class="btn btn-secondary me-3" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <i class="material-icons">list</i> &nbsp;
                                        View
                                    </button>
                                    <button type="button" (click)="isDownload=true;submitForm(formGroup);" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <i class="material-icons">file_download</i> &nbsp;
                                        Download
                                    </button>
                                </div>
                                <div class="text-end">
                                    <div class="spinner-border text-primary" role="status" *ngIf="submitLoading">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="data.data?.length" class="row">
    <div class="col-md-12 mt-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th rowspan="2">PO Customer</th>
                                        <th rowspan="2">Customer Name</th>
                                        <th rowspan="2">Customer SoW</th>
                                        <th rowspan="2">Vendor SoW</th>
                                        <th rowspan="2">UoM</th>
                                        <th rowspan="2">Target Price</th>
                                        <th [colSpan]="data.vendor_list?.length" class="text-center">
                                            Vendors
                                        </th>
                                    </tr>
                                    <tr>
                                        <th *ngFor="let v of data.vendor_list" class="text-end">{{v.vendor?.name}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data?.data; let i = index">
                                        <td>
                                            {{ item.purchase_order?.number }}
                                        </td>
                                        <td>
                                            {{ item.purchase_order?.contract?.customer_name }}
                                        </td>
                                        <td>{{ item.customer_boq?.sow_name }}</td>
                                        <td>
                                            {{ item.sow_name }}
                                        </td>
                                        <td>{{ item.uom }}</td>
                                        <td class="text-end">
                                            {{item.price | number}}
                                        </td>
                                        <td *ngFor="let v of data.vendor_list" class="text-end">
                                            {{(findPrice(item.id, v)?.price | number) || '-'}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
