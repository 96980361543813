<div class="row mb-4">
    <div class="col-sm-12 mx-auto">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <h4 class="card-title col-12 mb-4">Opname PO Vendor</h4>
                    <div class="text-center" *ngIf="!data.id">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <form *ngIf="data.id" #theForm="ngForm" (ngSubmit)="submitForm(formGroup)" novalidate class="col-12">
                        <div class="row" [formGroup]="formGroup">
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <input type="text" readonly [value]="data.number" placeholder="..." class="form-control" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <label>PO Number</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <input type="text" readonly [value]="data.is_additional ? data.po_additional?.contract?.project_name : data.pr_vendor?.contract?.project_name" placeholder="..." class="form-control" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <label>Project Name</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <input type="text" readonly [value]="data.is_additional ? data.po_additional?.po_site?.name : data.pr_vendor?.po_site?.name" placeholder="..." class="form-control" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <label>Site Name</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-floating mb-4">
                                    <div class="input-group">
                                        <div class="form-floating form-floating-group flex-grow-1">
                                            <input type="text" readonly (click)="tfDate.toggle()" ngbDatepicker #tfDate="ngbDatepicker" formControlName="requested_at" [class.is-invalid]="formGroup.get('requested_at')?.invalid && (theForm.submitted || formGroup.get('requested_at')?.touched)" placeholder="..." class="form-control border-end-0" [attr.disabled]="loading.status ? 'disabled' : null" required>
                                            <label>Request Date</label>
                                        </div>
                                        <div class="border border-start-0 d-flex align-items-center rounded-end" [class.border-danger]="formGroup.get('requested_at')?.invalid && (theForm.submitted || formGroup.get('requested_at')?.touched)">
                                            <button type="button" class="btn btn-sm p-0 m-0 me-2 text-muted" (click)="tfDate.toggle()" [attr.disabled]="loading.status ? 'disabled' : null">
                                                <i class="material-icons">today</i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('requested_at')?.invalid && (theForm.submitted || formGroup.get('requested_at')?.touched)">
                                        <small *ngIf="formGroup.get('requested_at')?.errors?.required">Please select date</small>
                                        <small *ngIf="formGroup.get('requested_at')?.errors?.serverError">{{ formGroup.get("requested_at")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-floating mb-4">
                                    <div class="form-floating">
                                        <input type="text" readonly [value]="''|me" placeholder="..." class="form-control" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <label>Request By</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <input type="text" readonly [value]="data.vendor?.name" placeholder="..." class="form-control" [attr.disabled]="loading.status ? 'disabled' : null">
                                    <label>Old Vendor</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-4">
                                    <ng-select class="form-control" [clearable]="false" placeholder="-" (open)="getVendor()" (change)="setVendor(formGroup.get('vendor_id')?.value)" formControlName="vendor_id" loadingText="Loading..." [loading]="loading.status" [class.is-invalid]="formGroup.get('vendor_id')?.invalid && (theForm.submitted || formGroup.get('vendor_id')?.touched)" required>
                                        <ng-option *ngFor="let item of (vendorList | async)" [disabled]="data.vendor_id==item.vendor_id" [value]="item.vendor_id">{{item.vendor?.name}}</ng-option>
                                    </ng-select>
                                    <label>New Vendor</label>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('vendor_id')?.invalid && (theForm.submitted || formGroup.get('vendor_id')?.touched)">
                                        <small *ngIf="formGroup.get('vendor_id')?.errors?.required">Please select new vendor</small>
                                        <small *ngIf="formGroup.get('vendor_id')?.errors?.serverError">{{ formGroup.get("vendor_id")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-floating mb-4">
                                    <div class="form-floating">
                                        <input type="text" formControlName="deduction" ngxNumberFormat="###,###,###,###,###" [class.is-invalid]="formGroup.get('deduction')?.invalid && (theForm.submitted || formGroup.get('deduction')?.touched)" placeholder="..." class="form-control" [attr.disabled]="loading.status ? 'disabled' : null">
                                        <label>Deduction</label>
                                    </div>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('deduction')?.invalid && (theForm.submitted || formGroup.get('deduction')?.touched)">
                                        <small *ngIf="formGroup.get('deduction')?.errors?.serverError">{{ formGroup.get("deduction")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-floating mb-4">
                                    <div class="form-floating">
                                        <textarea formControlName="notes" [class.is-invalid]="formGroup.get('notes')?.invalid && (theForm.submitted || formGroup.get('notes')?.touched)" placeholder="..." class="form-control textareabox" [attr.disabled]="loading.status ? 'disabled' : null"></textarea>
                                        <label>Notes</label>
                                    </div>
                                    <div class="text-danger position-absolute text-end w-100" *ngIf="formGroup.get('notes')?.invalid && (theForm.submitted || formGroup.get('notes')?.touched)">
                                        <small *ngIf="formGroup.get('notes')?.errors?.serverError">{{ formGroup.get("notes")?.errors?.serverError }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table *ngIf="details?.length" class="table">
                                    <thead>
                                        <tr>
                                            <th style="width:1%;" nowrap>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="allSow" (ngModelChange)="toggleAll()" [(ngModel)]="toggle_all" [ngModelOptions]="{standalone: true}" [disabled]="loading.status || !formGroup.get('vendor_id')?.value">
                                                    <label class="form-check-label" for="allSow">
                                                        Select All
                                                    </label>
                                                </div>
                                            </th>
                                            <th style="width:40%;">
                                                SoW
                                            </th>
                                            <th style="width:5%;" nowrap>
                                                UoM
                                            </th>
                                            <th class="text-end" style="width:10%;">
                                                Previous Qty
                                            </th>
                                            <th class="text-end" style="width:10%;">
                                                Last Qty
                                            </th>
                                            <th style="width:15%;">
                                                Opname Qty
                                            </th>
                                            <th *ngIf="formGroup.get('vendor_id')?.value" style="width:15%;" class="text-end">
                                                New Vendor Qty
                                            </th>
                                            <th *ngIf="formGroup.get('vendor_id')?.value" class="text-end" nowrap>
                                                New Vendor Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of details;index as i">
                                            <td nowrap>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" [disabled]="loading.status || !formGroup.get('vendor_id')?.value">
                                                </div>
                                            </td>
                                            <td [class.opacity-75]="!item.checked">
                                                {{item.sow_name}}
                                            </td>
                                            <td nowrap [class.opacity-75]="!item.checked">
                                                {{item.uom}}
                                            </td>
                                            <td class="text-end" [class.opacity-75]="!item.checked">
                                                {{item.planned_qty | number}}
                                            </td>
                                            <td class="text-end" [class.opacity-75]="!item.checked">
                                                {{item.last_qty | number}}
                                            </td>
                                            <td [class.opacity-75]="!item.checked">
                                                <input type="text" id="qty-{{item.id}}" ngxNumberFormat="###,###,###,###,###" (ngModelChange)="updateQty(item)" [(ngModel)]="item.old_vendor_qty" [ngModelOptions]="{standalone: true}" class="form-control" [disabled]="loading.status || !item.checked">
                                            </td>
                                            <td *ngIf="formGroup.get('vendor_id')?.value" [class.opacity-75]="!item.checked" class="text-end">
                                                {{(item.new_vendor_qty | number)}}
                                            </td>
                                            <td *ngIf="formGroup.get('vendor_id')?.value" [class.opacity-75]="!item.checked" class="text-end">
                                                <span *ngIf="item.vendor_price">{{(item.vendor_price | number)}}</span>
                                                <em *ngIf="!item.vendor_price" class="text-warning small">New vendor doesn't have price for this SoW!</em>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="d-flex justify-content-between align-items-center border-top pt-1">
                                    <div>&nbsp;</div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-secondary" (click)="back()" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">close</i> &nbsp; Cancel</button>
                                        &nbsp;
                                        <button type="submit" class="btn btn-primary" [attr.disabled]="loading.status ? 'disabled' : null"><i class="material-icons">save</i> &nbsp; Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
