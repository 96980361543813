import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { DatePickerParserService } from 'src/app/services/date-picker-parser.service';
import { FileHandlerService } from 'src/app/services/file-handler.service';
import { HttpService } from 'src/app/services/http.service';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { v4 } from 'uuid';

@Component({
    selector: 'app-customer-billing-document-update',
    templateUrl: './customer-billing-document-update.component.html',
    styleUrls: ['./customer-billing-document-update.component.scss']
})
export class CustomerBillingDocumentUpdateComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private http: HttpService,
        private location: Location,
        public loading: LoadingService,
        private el: ElementRef,
        private toastr: ToastrService,
        private dateParse: DatePickerParserService,
        private auth: AuthService,
        public fileHandler: FileHandlerService,
    ) {
        this.route.params.subscribe(e => {
            this.id = e.id;
        });
    }
    private apiPath = 'customer-billing-docs';
    id: any;
    formChanged = false;
    data: any = {};
    isCreate: boolean = true;

    submitForm(): void {
        const noName = this.data.details.find((e: any) => !e.document_name) || {};
        if (noName.ref) {
            noName.document_name_error = true;
            return;
        }
        const noDate = this.data.details.find((e: any) => !e.document_date) || {};
        if (noDate.ref) {
            noDate.document_date_error = true;
            return;
        }
        const data = {
            id: this.data.id,
            details: this.data.details.map((e: any) => {
                return {
                    id: e.id || null,
                    ref: e.ref,
                    document_name: e.document_name,
                    document_date: this.dateParse.toYMD(e.document_date)
                }
            })
        };
        let fdata = new FormData();
        this.data.details.filter((e: any) => e.file?.file?.name && e.file?.view).forEach((e: any) => {
            const ext = e.file?.file?.name?.split('.').pop();
            fdata.append('documents[]', e.file?.file, e.ref + '.' + ext);
        });
        fdata.append('data', JSON.stringify(data));
        const path = this.data.status == -2 ? '/create' : '/update';
        this.http.Post(this.apiPath + path, fdata).then((r: any) => {
            if (r.success) {
                this.toastr.success('Data saved successfully', 'Success');
                this.back();
            }
        });
    }

    downloadFile(file: string): void {
        const url = environment.apiUrl + '/download/' + file + '?token=' + this.auth.tokenData.access_token;
        window.open(url, '_blank');
    }

    browseFile(i: number): void {
        this.el.nativeElement.querySelectorAll(`.file-input-${i}`)?.[0]?.click();
    }

    rejectRemarks(): any {
        const appr = (this.data.approvals.find((e: any) => e.status == -1)) || {};
        return appr.remarks || null;
    }

    addDetail(): void {
        this.data.details.push({ ref: v4(), file: {} });
    }

    removeDetail(i: number): void {
        this.data.details.splice(i, 1);
        if (!this.data.details.length) {
            this.addDetail();
        }
    }

    back(): void {
        this.location.back();
    }

    ngOnInit(): void {
        this.http.Get(this.apiPath + '/' + this.id, {}).then((r: any) => {
            if (r.success && r?.response?.result?.data?.id) {
                this.data = r?.response?.result?.data;
                this.data.details = r?.response?.result?.data.details.map((e: any) => {
                    e.ref = e.id;
                    if (e.document_date) {
                        e.document_date = this.dateParse.toObject(e.document_date);
                    }
                    if (e.file) {
                        e.file = { file: { name: e.file }, download: true };
                    } else {
                        e.file = { file: {} };
                    }
                    return e;
                });
                if (!this.data.details.length) {
                    this.addDetail();
                }
            } else {
                this.back();
            }
        });

    }

}

