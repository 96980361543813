import { Injectable } from '@angular/core';
import { deleteToken, getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { NotificationService } from './notification.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class FcmMessagingService {

    constructor(
        private http: HttpService,
        private auth: AuthService,
        private notificationService: NotificationService
    ) { }

    init(): void {
        if ('serviceWorker' in navigator) {
            Notification.requestPermission().then((permission) => {
                if (permission != 'granted') {
                    return;
                }
                const messaging = getMessaging();
                navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' }).
                    then(serviceWorkerRegistration => {
                        getToken(messaging, {
                            serviceWorkerRegistration,
                            vapidKey: environment.firebase.vapidKey,
                        }).then((token) => {
                            this.http.Post('auth/fcm-token', { token: token }, true);
                            onMessage(messaging, (e) => {
                                if (e.data?.to == this.auth?.userData?.email) {
                                    this.notificationService.count++;
                                }
                            });
                        });
                    });
            });
        }
    }

    unregister() {
        const messaging = getMessaging();
        deleteToken(messaging);
    }
}
